.landing-mea {
  background-color: #1d4b89;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 30px;
  height: 400px;
}

.landing-mea-cd {
  background-image: url("../images/bg_bienvenue.jpg");
}

.landing-mea-lp {
  background-image: url("../images/bg_bienvenue_lp.jpg");
}

.landing-mea-fd {
  background-image: url("../images/bg_bienvenue_fd.jpg");
}

.landing-mea > div {
  height: 400px;
  align-items: center;
}

.img-connect {
  background-image: url("../images/mockup-my-dentaire.jpg");
  background-position: top;
  background-size: cover;
  border-radius: 7px;
  height: 400px;
}

.img-connect-lp {
  background-image: url("../images/bg_connect-lp.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  height: 400px;
}

.img-connect-fd {
  background-image: url("../images/bg_connect-fd.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 7px;
  height: 400px;
}

.card-landing-cd {
  @extend %shadow;
  @extend .card;
  height: 100%;
  border: none;
  margin: auto;
  text-align: center;
  font-size: 1rem;
  a {
    font-size: 0.8rem !important;
  }
  .img {
    filter: invert(59%) sepia(23%) saturate(1891%) hue-rotate(162deg)
      brightness(93%) contrast(88%);
    color: #25aae1;
  }
  img {
    height: 50px;
    width: 50px;
    margin: 20px auto 10px auto;
  }
  h5 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    strong {
      font-size: 2rem;
      font-weight: bold;
      line-height: 2rem;
    }
  }
  .exemple {
    text-decoration: underline;
    font-size: 1rem !important;
    color: $bleu-ciel;
  }
}

.card-footer {
  background-color: $white !important;
  border: none;
  padding: 0 1.25rem 1.25rem 1.25rem !important;
}

.card-landing-30 {
  width: 26%;
}

.card-landing-50 {
  width: 46%;
}

.card-landing-90 {
  width: 90%;
}

.card-landing-100 {
  width: 100%;
}

.fd-animez {
  background-image: url("../images/globe.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: top right;
}

.suggest-search {
  span {
    color: $bleu-ciel;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 1rem;
    width: 100%;
  }
  ul {
    margin-bottom: 0 !important;
  }
  ul li {
    display: inline-block;
  }
  .voir-plus {
    display: block;
    text-decoration: underline;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}

.suggest-text {
  text-align: center;
  img {
    width: 100%;
    border-radius: 7px;
    margin-top: 2rem;
  }
}

.pill-grey {
  line-height: 2.2rem;
  border-radius: 2rem !important;
  border: none;
  font-size: 0.8rem;
  padding: 0 15px 0 15px;
  background-color: $gris-clair;
  margin: 7px;
  width: fit-content;
}

.gradient-grey {
  background: rgb(241, 243, 248);
  background: -moz-linear-gradient(
    0deg,
    rgba(241, 243, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(241, 243, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(241, 243, 248, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1f3f8",endColorstr="#ffffff",GradientType=1);
}

.btn-orange {
  background: rgb(239, 93, 24);
  background: -moz-linear-gradient(
    90deg,
    rgba(239, 93, 24, 1) 0%,
    rgba(245, 143, 38, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(239, 93, 24, 1) 0%,
    rgba(245, 143, 38, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(239, 93, 24, 1) 0%,
    rgba(245, 143, 38, 1) 100%
  );
  color: $white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef5d18",endColorstr="#f58f26",GradientType=1);
  text-align: center;
  border: 0;
  border-radius: 7px;
  padding: 0 35px;
  line-height: 42px;
}

.btn-orange:hover {
  background: rgb(239, 93, 24) !important;
  transition: tranform 275ms;
  color: $white !important;
}

.h1-landing {
  font-weight: bold;
  font-size: 2.2rem;
  color: $bleu-ciel;
  line-height: 2rem;
}

.h2-landing {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: normal;
  color: $noir-text;
  margin-bottom: 2rem;
  strong {
    font-weight: bold;
    font-size: 2.2rem;
    color: $bleu-ciel;
  }
}

.h2-white-landing {
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: normal;
  color: $white;
  margin-bottom: 2rem;
  strong {
    font-weight: normal;
    font-size: 2rem;
    color: $white;
  }
}

#exampleModalCD .modal-title{
  color: #25aae1;
}

@media (min-width: 576px) {
  #exampleModalCD .modal-dialog{
    max-width: 1000px;
  }
}

@media (max-width: 1024px) {
  .suggest {
    flex-direction: column-reverse;
  }

  .landing-partenaire {
    margin: auto;
  }

  .landing-partenaire > div {
    margin: auto;
  }

  .landing-mea-cd,
  .landing-mea-lp,
  .landing-mea-fd {
    background-color: #1d4b89;
    background-image: none;
  }

  .fd-animez {
    background-image: none;
  }
}
