// Var
$md-icon-size : 25px;
$md-icon-bckgrnd : #303934;
$md-icon-bckgrnd-mob : #8de814;

%base-icon {
  display: inline-flex;
  background-color: $md-icon-bckgrnd;
  height: $md-icon-size;
  width: $md-icon-size;
  content: '';
  margin-right: 10px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.md-ico-tdb:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mon-tableau-de-bord.svg);
  mask-image: url(../images/md/mon-tableau-de-bord.svg);
}

.md-ico-profil:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mon-profil.svg);
  mask-image: url(../images/md/mon-profil.svg);
}

.md-ico-password:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mon-mot-de-passe.svg);
  mask-image: url(../images/md/mon-mot-de-passe.svg);
}

.md-ico-friend:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mes-confreres.svg);
  mask-image: url(../images/md/mes-confreres.svg);
}
.md-ico-product:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mes-produits-favoris.svg);
  mask-image: url(../images/md/mes-produits-favoris.svg);
}
.md-ico-address:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mon-carnet-d-adresse.svg);
  mask-image: url(../images/md/mon-carnet-d-adresse.svg);
}
.md-ico-event:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mes-evenements.svg);
  mask-image: url(../images/md/mes-evenements.svg);
}
.md-ico-formation:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mes-formations.svg);
  mask-image: url(../images/md/mes-formations.svg);
}
.md-ico-annonce:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mes-annonces.svg);
  mask-image: url(../images/md/mes-annonces.svg);
}
.md-ico-job:before{
  @extend %base-icon;
  -webkit-mask-image: url(../images/md/mes-offres-d-emploi.svg);
  mask-image: url(../images/md/mes-offres-d-emploi.svg);
}

.md-ico-logout:before{
  @extend %base-icon;
  background-color: #1199d2;
  -webkit-mask-image: url(../images/md/me-deconnecter.svg);
  mask-image: url(../images/md/me-deconnecter.svg);
}

.md-ico-mob-bckd:before {
  background-color: $white !important;
}

.md-ico-mob-bckd:before:hover {
  background-color: $md-icon-bckgrnd-mob !important;
}
