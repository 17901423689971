@import "./variable";
@import "./reset";
@import "./font";
@import "./icon";
@import "./icon-md";

@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../node_modules/slick-carousel/slick/slick.scss";

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

$grid-columns: 24;

@import "../../node_modules/bootstrap/scss/grid";
@import "./main";
@import "./nav";
@import "./lazyloading";
@import "./breadcrumb";
@import "./autocomplete";
@import "./form";
@import "./pagination";
@import "./footer";
@import "./landing";
@import "./fiche-fd-lp";
@import "./espace-pro";
@import "./my-dentaire";
@import "./card-horyzontal";
@import "./fiche-event";
@import "./fiche-produit";
@import "./responsive";

@import "../../node_modules/select2/dist/css/select2.min.css";
