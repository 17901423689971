footer {
  @extend %width-full-content;
  color: $white;
  font-size: .8rem;
  margin-top: 4rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  text-align: center;

  li {
    display: inline;
    padding-left: .6rem;
    padding-right: .6rem;
    line-height: 2rem;
  }

  a, a:hover {
    color: $white;
    text-decoration: none;
  }
}

@media (max-width: 1024px) {


}