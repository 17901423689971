.card-horyzontal {
  @extend %shadow;
  @extend .card;
  //margin: 1%;
  margin: 0 0 2rem 0;
  width: 100%;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px 0;

  p {
    font-size: .8rem;
    margin-bottom: .2rem;
  }

  ul{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

  }

  li {
    line-height: 2rem;
    border-radius: 2rem !important;
    border: none;
    padding: 0 15px 0 15px;
    background-color: $gris-clair;
    margin: 15px 30px 0 0;
    font-size: .8rem;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

}

.card-horyzontal-pro {
  @extend .card-horyzontal;
}