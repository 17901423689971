/*.icon-bulle {
  -webkit-mask: url('../images/informations.svg');
  mask: url('../images/informations.svg');
}

.icon{
  display: inline-block;
  width: 32px;
  height: 30px;
  background: white;
  mask-size: cover;
  -webkit-mask-size: cover;
  position: relative;
  top: 7px;
}*/


.circle-icon {
  //background: $bleu-ciel !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-right: 1rem;
  display: inline-block;
  background-color: $bleu-ciel !important;
  background-size: 70% !important;
}

.icon-map {
  background-color: $bleu-ciel;
  width: 48px;
  height: 48px;
  display: inline-block;
  -webkit-mask: url('../images/pin.svg') no-repeat 50% 50%;
  mask: url('../images/pin.svg') no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.icon-map-white {
  @extend .circle-icon;
  background: url('../images/pin-white.svg')  no-repeat center;
}

.icon-phone-white {
  @extend .circle-icon;
  background: url('../images/phone-white.svg')  no-repeat center;
}

.icon-fax {
  @extend .circle-icon;
  background: url('../images/fax.svg')  no-repeat center;
}

.icon-web-white {
  @extend .circle-icon;
  background: url('../images/mail-white.svg')  no-repeat center;
}

.icon-linkedin-white {
  @extend .circle-icon;
  background: url('../images/linkedin-white.svg')  no-repeat center;
}

.icon-twitter-white {
  @extend .circle-icon;
  background: url('../images/twitter-white.svg')  no-repeat center;
}

.icon-facebook-white {
  @extend .circle-icon;
  background: url('../images/facebook-white.svg')  no-repeat center;
}

.icon-instagram-white {
  @extend .circle-icon;
  background: url('../images/instagram-white.svg')  no-repeat center;
}

.icon-youtube-white {
  @extend .circle-icon;
  background: url('../images/youtube-white.svg')  no-repeat center;
}

.icon-arrow-right-white {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  background-color: $bleu-ciel !important;
  background-size: 90% !important;
  background: url('../images/arrow-right-white.svg')  no-repeat center;
}

.icon-pro {
  background-color: $bleu-ciel;
  width: 28px;
  height: 28px;
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
  margin-right: 1rem;
}

.block-onpc .icon-pro {
  background-color: $white !important;
}

.icon-pro-profil {
  @extend .icon-pro;
  -webkit-mask: url('../images/compte.svg') no-repeat 50% 50%;
  mask: url('../images/compte.svg') no-repeat 50% 50%;
}

.icon-pro-password {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-password.svg') no-repeat 50% 50%;
  mask: url('../images/picto-password.svg') no-repeat 50% 50%;
}

.icon-pro-formation {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-formations.svg') no-repeat 50% 50%;
  mask: url('../images/picto-formations.svg') no-repeat 50% 50%;
}

.icon-pro-exposition {
  @extend .icon-pro;
  -webkit-mask: url('../images/expositions.svg') no-repeat 50% 50%;
  mask: url('../images/expositions.svg') no-repeat 50% 50%;
}

.icon-pro-offre {
  @extend .icon-pro;
  -webkit-mask: url('../images/ico_collaborateurs.svg') no-repeat 50% 50%;
  mask: url('../images/ico_collaborateurs.svg') no-repeat 50% 50%;
}

.icon-pro-annonce {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-comm-annuaire.svg') no-repeat 50% 50%;
  mask: url('../images/picto-comm-annuaire.svg') no-repeat 50% 50%;
}

.icon-pro-presentation {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-presentation.svg') no-repeat 50% 50%;
  mask: url('../images/picto-presentation.svg') no-repeat 50% 50%;
}

.icon-pro-catalogue {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-catalogues.svg') no-repeat 50% 50%;
  mask: url('../images/picto-catalogues.svg') no-repeat 50% 50%;
}

.icon-pro-reseau {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-reseaux.svg') no-repeat 50% 50%;
  mask: url('../images/picto-reseaux.svg') no-repeat 50% 50%;
}

.icon-pro-actualites {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-actualites.svg') no-repeat 50% 50%;
  mask: url('../images/picto-actualites.svg') no-repeat 50% 50%;
}

.icon-pro-dentaire {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-fichiers-dentaires.svg') no-repeat 50% 50%;
  mask: url('../images/picto-fichiers-dentaires.svg') no-repeat 50% 50%;
}

.icon-pro-communication {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-comm-annuaire.svg') no-repeat 50% 50%;
  mask: url('../images/picto-comm-annuaire.svg') no-repeat 50% 50%;
}

.icon-pro-illustration {
  @extend .icon-pro;
  -webkit-mask: url('../images/demande-info.svg') no-repeat 50% 50%;
  mask: url('../images/demande-info.svg') no-repeat 50% 50%;
}

.icon-pro-produit {
  @extend .icon-pro;
  -webkit-mask: url('../images/picto-produits.svg') no-repeat 50% 50%;
  mask: url('../images/picto-produits.svg') no-repeat 50% 50%;
}

.icon-pro-evenement {
  @extend .icon-pro;
  -webkit-mask: url('../images/ico_evenements.svg') no-repeat 50% 50%;
  mask: url('../images/ico_evenements.svg') no-repeat 50% 50%;
}

.icon-fiche {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: .2rem;
}

.icon-fiche-adresse {
  @extend .icon-fiche;
  background: url('../images/pin.svg')  no-repeat center;
}
.icon-fiche-telephone {
  @extend .icon-fiche;
  background: url('../images/phone.svg')  no-repeat center;
}

.icon-fiche-web {
  @extend .icon-fiche;
  background: url('../images/mail.svg')  no-repeat center;
}



.icon-card-elearning {
  width: 30px;
  height: 30px;
  border-top-left-radius: 7px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  background-color: $bleu-ciel !important;
  background-size: 70% !important;
  background: url('../images/mail-white.svg')  no-repeat center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.icon-diplome {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-right: .5rem;
  display: inline-block;
  background-size: 70% !important;
  background: url('../images/diplome.svg')  no-repeat center;
}

.icon-calendar-white {
  width: 1.5rem;
  height: 1.5rem;
  background: url("../images/ico_evenements-white.svg") no-repeat center;
  display: inline-block;
  background-size: 80% !important;
  margin-right: 10px;
}
