$bleu-gris: #2f3944;
$bleu-ciel: #25aae1;
$bleu-fonce: #235087;
$bleu-nuit: #00194c;
$white: #fff;
$gris: #414d5a;
$background-bleu-clair: #ebeff6;
$gris-clair: #f1f3f8;
$noir-text: #2f3944;
$orange: #e98f26;
$rouge: #ef4040;
    
// Liste des exposants Lettres ABCdaire à mettre en #507cad quand actif
$bleu-le: #507cad;

$bleu-deg-a: rgba(47, 57, 68, 1);
$bleu-deg-b: rgba(63, 75, 87, 1);

$body-bg: #fff !default;
$text-color: $bleu-gris !default;
$link-hover-color: $bleu-ciel !default;

$headings-color: $bleu-gris !default;

$max-content-width: 1400px;

/*MY AD*/
$md-vert: #8de814;
$md-gris-fonce: #1e2734;
$md-gris-clair: #f5f6f7;
$md-border-radius: 8px;
