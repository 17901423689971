@import "./variable";

%width-content {
  max-width: $max-content-width;
  /*padding-left: 5%;
  padding-right: 5%;*/
  margin: 0 auto;
}

%width-full-content {
  padding-left: 5%;
  padding-right: 5%;
}

%shadow {
  border-radius: 4px;
  -webkit-box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
  box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
}

.width-content {
  @extend %width-content;
}

.width-full-content {
  @extend %width-full-content;
}

.shadow {
  @extend %shadow;
}

.in-width-full-content {
  max-width: $max-content-width;
  margin: 0 auto;
  padding-left: min(5%);
  padding-right: min(5%);
  height: 100%;
}

%deg-btn-bleu {
  background: $bleu-ciel;
  background: -moz-linear-gradient(
    90deg,
    rgba(66, 163, 220, 1) 0%,
    rgba(61, 148, 200, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(66, 163, 220, 1) 0%,
    rgba(61, 148, 200, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(66, 163, 220, 1) 0%,
    rgba(61, 148, 200, 1) 100%
  );
  color: $white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#42a3dc",endColorstr="#3d94c8",GradientType=1);
}

%deg-bg-bleu-ciel {
  background: rgb(19, 149, 203);
  background: -moz-linear-gradient(
    90deg,
    rgba(19, 149, 203, 1) 0%,
    rgba(19, 138, 188, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(19, 149, 203, 1) 0%,
    rgba(19, 138, 188, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(19, 149, 203, 1) 0%,
    rgba(19, 138, 188, 1) 100%
  );
  color: $white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1395cb",endColorstr="#138abc",GradientType=1);
}

body {
  overflow-x: hidden;
  color: $noir-text;
}

.w-30 {
  width: 30%;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  color: $noir-text;
  text-decoration: none;
}

a:hover {
  color: $noir-text;
  text-decoration: none;
}

h1 {
  font-size: 1.625rem;
  font-weight: bold;
}

h2 {
  font-size: 1.3rem;
  color: $bleu-ciel;
  font-weight: bold;
}

h3 {
  font-size: 1.15rem;
  font-weight: bold;
}

h4 {
  font-size: 1.0rem;
  font-weight: bold;
}

input {
  border: none;
  border-radius: 7px;
  line-height: 32px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.h1-white {
  font-size: 2rem;
  color: $white;
}

.h2-white {
  color: $white;
  font-size: 1.375rem;
}

.form-white {
  select,
  input,
  input:focus {
    border: 1px solid $white;
    background-color: transparent;
    color: $white;
    font-size: 0.75rem;
  }
  label,
  button {
    font-size: 0.75rem;
  }

  small {
    font-size: 0.7rem;
  }
}

/* popup */
.popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 1;
  z-index: 99998;
}
#popup {
  display:none;
  .popup-window {
    z-index: 99999;
    position: relative;
    margin: 70px auto;
    width: 562px;
    display: flex;
    justify-content: center;
    > a {
      position: absolute;
      top: 0;
      right: 15px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #333;
    }
    > a:hover {
      color: #ef5d18;
    }
    .popup-buttons {
      height:71px;
      background:#fff;
      position: absolute;
      bottom: 4px;
      width: 99%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      a {
        background:#ef5d18;
        border-radius:15px;
        text-transform: uppercase;
        color:#fff;
        font-weight: bold;
        padding: 5px 20px;
        font-size: 14px;
      }
    }
  }
}

/*pubs*/
.ban-billboard-top .banners{
  max-height:270px;
}
.ban-billboard-top div.preloader{
  height:250px !important;
}
.ban-parallax-top .banners{
  height: 205px;
}
.bloc-pub-sidebar .banners{
  height: 250px;
}
.banners{
  div.preloader{
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #ban-slider-top .carousel-item{
    max-height:270px;
  }
  #ban-slider-megabanner .carousel-item, #ban-slider-megabanner .carousel-item img{
    max-height:150px;
  }
  #ban-slider-sidebar .carousel-item{
    max-height:250px;
  }
  #ban-parallax-top .carousel-item{
    max-height:205px;
  }
}


.home-titre {
  @extend %width-content;
  font-family: "Optie-extended", Helvetica, Arial, sans-serif;
  //margin-bottom: 2rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1rem 0;
  strong {
    font-family: "Optie-bold", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }
  img {
    vertical-align: middle;
    height: 1.2rem;
  }
  span {
    vertical-align: middle;
  }
}

.deg-bleu-fonce {
  background: $bleu-gris;
  background: -moz-linear-gradient(90deg, $bleu-deg-a 0%, $bleu-deg-b 100%);
  background: -webkit-linear-gradient(90deg, $bleu-deg-a 0%, $bleu-deg-b 100%);
  background: linear-gradient(90deg, $bleu-deg-a 0%, $bleu-deg-b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2f3944",endColorstr="#3f4b57",GradientType=1);
}

.block-orange {
  @extend %width-full-content;
  background: rgb(239, 93, 24);
  background: -moz-linear-gradient(
    90deg,
    rgba(239, 93, 24, 1) 0%,
    rgba(245, 143, 38, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(239, 93, 24, 1) 0%,
    rgba(245, 143, 38, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(239, 93, 24, 1) 0%,
    rgba(245, 143, 38, 1) 100%
  );
  color: $white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef5d18",endColorstr="#f58f26",GradientType=1);
  margin-bottom: 30px;
  padding-bottom: 40px;
  padding-top: 40px;
}

.block-rouge {
  @extend %width-full-content;
  background: rgb(239, 64, 54);
  background: -moz-linear-gradient(
    90deg,
    rgba(239, 64, 54, 1) 0%,
    rgba(188, 50, 42, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(239, 64, 54, 1) 0%,
    rgba(188, 50, 42, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(239, 64, 54, 1) 0%,
    rgba(188, 50, 42, 1) 100%
  );
  color: $white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef4036",endColorstr="#bc322a",GradientType=1);
  margin-bottom: 70px;
  padding-bottom: 40px;
  padding-top: 40px;

  .expo-link {
    color: $white;
    &:hover {
      color: #2f3944;
    }
  }
}

.block-bleu {
  @extend %width-full-content;
  background: rgb(28, 75, 137);
  background: -moz-linear-gradient(
    90deg,
    rgba(28, 75, 137, 1) 0%,
    rgba(24, 64, 116, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(28, 75, 137, 1) 0%,
    rgba(24, 64, 116, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(28, 75, 137, 1) 0%,
    rgba(24, 64, 116, 1) 100%
  );
  color: $white;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1c4b89",endColorstr="#184074",GradientType=1);
  margin-bottom: 70px;
  padding-bottom: 40px;
  padding-top: 40px;
}

.bloc-recherche-categorie {
  @extend %width-content;
}

.list-btn-recherche-produit {

  .card-two .card {
    width: calc((100% / 2) - 20px);
  }

  .card-three .card {
    width: calc((100% / 3) - 20px);
  }

  .card {
    margin-right: 20px;
    overflow: hidden;
    border-radius: 0 !important;
  }

  .card-img-overlay {
    background-image: linear-gradient(0deg,rgba(0, 0, 0, .4) 0%, rgba(31, 31, 31, .4) 11%, rgba(54, 54, 54, .3) 22%, rgba(79, 79, 79, .3) 33%, rgba(105, 105, 105, .1) 44%, rgba(133, 133, 133, .1) 56%, rgba(163, 163, 163, .1) 67%, rgba(191, 191, 191, 0) 78%, rgba(224, 224, 224, 0) 89%, rgba(255, 255, 255, 0) 100%);
  }

  .card-img-overlay-cd {
    padding: 0.25rem !important;
    .pill-search {
      padding: 0 5px !important;
      font-size: .6rem !important;
    }
  }

  .card-title {
    font-weight: normal;
    height: unset;
    text-align: center;
  }

  .card-two .card-title {
    font-size: 2rem;
  }

  .pill-search {
    border-radius: .3rem !important;
    margin: 0 0 .5rem .8rem;
    text-align: center;
  }

  .pill-search:hover {
    background-color: #bedfec !important;
  }
}

.bg-bleu-clair {
  background-color: $background-bleu-clair;
}

.title-gris {
  background-color: $gris;
  border-radius: 7px;
  color: $white;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  //margin-top: 35px;
  padding-bottom: 4px;
  padding-left: 1%;
  padding-top: 4px;
  text-transform: uppercase;

  img {
    vertical-align: middle;
    height: 0.8rem;
  }
  span {
    vertical-align: middle;
  }
}

.btn-gris,
.btn-gris:hover {
  background-color: $gris;
  border: 0;
  border-radius: 7px;
  color: $white;
  font-size: 0.75rem;
  line-height: 1.2rem;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
}

.btn-gris:hover {
  background-color: #637488 !important;
}

.btn-bleu-search {
  @extend %deg-btn-bleu;
  text-align: center;
  border: 0;
  border-radius: 7px;
  padding: 9px 35px;
  //line-height: 42px;
  /*display: block;*/
}
.btn-bleu-search:hover {
  background: $bleu-ciel !important;
  transition: tranform 275ms;
}

.btn-bleu {
  @extend %shadow;
  @extend %deg-btn-bleu;
  font-size: 0.8rem;
  text-align: center;
  border: 0;
  border-radius: 7px;
  padding: 0 15px;
  line-height: 35px;
  display: block;
  max-height: 35px;
}

.btn-press {
  font-size: 0.9rem;
  font-weight: bold;
  background-color: #25aae1;
  color: $white;
  text-align: center;
  border: 0;
  border-radius: 25px;
  padding: 0 15px;
  line-height: 35px;
  display: inline-block;
  max-height: 35px;
  margin: 15px 0;
}

.pill-search {
  line-height: 2rem;
  border-radius: 2rem !important;
  border: 1px #25aae1 solid;
  padding: 0 20px 0 20px;
  background-color: $white;
  font-weight: bold;
  margin: 1rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.pill-search.active {
  background-color: $bleu-ciel !important;
  color: $white;
}

.pill-search:not(.disabled, .active):hover {
  background-color: #bedfec !important;
  color: $white;
}

.pill-cd {
  line-height: 2rem;
  border-radius: 2rem !important;
  border: unset; //1px #25aae1 solid;
  padding: 0 20px 0 20px;
  background-color: $gris-clair;
  font-weight: bold;
  margin: 1rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  position: relative;
}

.pill-cd.active {
  background-color: $gris !important;
  color: $white;
  position: relative;
}

.pill-cd.active:before {
  content: "";
  position: absolute;
  right: calc(50% - 10px);
  top: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $gris transparent transparent transparent;
}

.pill-cd:not(.disabled, .active):hover {
  background-color: $gris !important;
  color: $white;
}

.pill-comp {
  line-height: 2rem;
  border-radius: 2rem !important;
  border: 1px #25aae1 solid;
  padding: 0 20px 0 20px;
  background-color: $white;
  font-weight: bold;
  margin: 1rem;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.pill-comp.active {
  background-color: $bleu-ciel !important;
  color: $white;
}

.cd-block-pro {
  background-color: $gris;
  padding: 15px;
  text-align: center !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: $border-radius;
  color: white;
  strong {
    text-align: center !important;
  }
}

.expandable-list::before {
  content: "";
  height: 100%;
  width: 5px;
  background: $bleu-ciel;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.titre-categorie {
  color: #babec3;
}

.accordion {
  @extend %shadow;
  position: relative;

  .card {
    border-radius: 0;
    border: 0;
    text-align: left;

    .card-header {
      background-color: transparent;
      border-bottom: 0;
      padding: 0.6rem 2rem 0.6rem 1.25rem;
      background-size: 15px 15px;
      background-position: center right 15px;
      background-repeat: no-repeat;
      transition: background-image 0.15s ease-in-out;

      span {
        transition: color 0.15s ease-in-out;
      }
    }

    .card-header[aria-expanded="false"] {
      background-image: url("../images/arrow-accordion.svg");
    }

    .card-header[aria-expanded="true"] {
      background-image: url("../images/arrow-accordion-open-up.svg");
    }

    .card-header[aria-expanded="true"] span {
      color: $bleu-ciel;
    }

    .card-body {
      padding: 0.5rem 2.5rem 0;
      font-size: 14px;

      a:hover {
        color: $bleu-ciel;
      }
    }
  }

  .card:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.home-3-btn {
  @extend %width-full-content;
  //background-image: url("../images/home-bg-3-btn.jpg");
  background-image: url("../images/background-home.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 200px;
  overflow: hidden;
  margin-bottom: 50px;
}

.home-baseline {
  @extend %width-full-content;
  display: flex;

  h1 {
    font-size: 1.5rem;
    text-align: center;
    padding: 3rem;

    span {
      color: $bleu-ciel;
      display: block;
      font-weight: 600;
    }
  }

  .baseline-left {
    width: 10%;
    align-items: center;
    text-align: center;

    h2 {
      color: $white;
    }
  }

  .baseline-right {
    width: 90%;
    display: flex;

    .card-home {
      @extend %shadow;
      border-radius: 7px;
      height: 110px;
      max-width: 350px;
      text-align: center;
      width: 31%;
      overflow: hidden;

      h3 {
        font-weight: bold;
        display: block;
        line-height: 1.3rem;
        font-size: 1rem;
      }

      a {
        line-height: 2rem;
        display: inline-block;
        margin-top: 10px;
      }
    }

    .cta-right {
      background: $white;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .card-home.cd .bkg-img {
      background: url("../images/homage_usertype_bg_cd.2.jpg") no-repeat;
      background-size: 110%;
      overflow: hidden
    }
    .card-home.lp .bkg-img  {
      background: url("../images/homage_usertype_bg_prothesiste.2.jpg") no-repeat;
      background-size: 110%;
      overflow: hidden
    }
    .card-home.fd .bkg-img  {
      background: url("../images/homage_usertype_bg_fd.2.jpg") no-repeat;
      background-size: 110%;
      overflow: hidden;
    }
  }
}

.produit-une {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.search-form {
  padding-top: 1.5rem;
  padding-bottom: 0rem;
  .algolia-autocomplete {
    display: unset !important;
  }
}

.home-input-search,
.home-input-search:focus {
  border: 0;
  border-radius: 7px 0px 0px 7px;
  box-shadow: none;
  line-height: 40px;
  margin-right: 0;
  padding-left: 20px;
  width: 430px;
  vertical-align: baseline !important;
}

.input-search,
.input-search:focus {
  border: 0;
  border-radius: 7px;
  box-shadow: none;
  line-height: 40px;
  margin-right: 0;
  padding: 0 20px 0 20px;
  width: 540px;
}

.home-select-search {
  color: $white;
  border: 0;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  line-height: 40px;
  height: 42px;
  //margin-left: -4px;
  margin: 10px 0 10px -4px;
  outline: none;
  padding: 0 20px;
  background: url("../images/arrow-select-search-down.png") no-repeat $gris;
  background-position: right 20px top 16px;
  -webkit-appearance: none;
}

.home-search-spe {
  display: flex;
  div {
    width: 33%;
  }
  a,
  a:hover {
    color: $white;
  }
}

.home-li-search > li {
  font-size: 1.1rem;
  font-weight: bolder;
  line-height: 1.6rem;
  list-style: disc;
  margin-left: 25px;
  padding-left: 5px;
  vertical-align: middle;
}
.home-li-search > li > a {
  font-size: 1rem;
  font-weight: normal;
  color: $white;
}

.home-li-search > li:last-child {
  content: "";
  display: inline-block;
  margin-left: 0;
  margin-top: 1rem;
  background: url("../images/icone-geolocalisation.png") no-repeat left;
}

.home-li-search > li:last-child > a {
  font-weight: bolder;
  padding-left: 25px;
}

.slider-product {
  margin-left: 10%;
  margin-right: 10%;
  width: auto;
}

.plus-produit-une {
  margin-left: 10%;
  margin-right: 10%;
  text-align: right;
}

.pt-lien-gris {
  color: $gris;
  font-size: 0.8rem;
  text-decoration: underline;
}

.home-block-info {
  margin: 0;
  /*padding-left: 5%;
  padding-right: 5%*/
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.8rem;
  display: flex;

  .content {
    width: 50%;
  }
  .content:first-child {
    margin-right: 2rem;
  }
  ul {
    column-count: 2;
  }
}

.a-add-contact {
  text-decoration: underline;
  font-size: .8rem;
}

.a-add-contact:after {
  display: inline-flex;
  width: 20px;
  height: 20px;
  content: '';
  background-color: $bleu-ciel;
  margin-left: 5px;
  -webkit-mask: url('../images/favori.svg') no-repeat 15% 15%;
  mask: url('../images/favori.svg') no-repeat 15% 15%;
  -webkit-mask-size: cover;
  mask-size: cover;
  vertical-align: middle;
}

.home-block-organisme {
  @extend %shadow;
  background-color: $white;
  margin-top: 30px;
  background-image: url("../images/illustration_livre.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 40%;
  padding: 1.5rem;
  display: flex;
  width: 100%;

  .content:first-child {
    width: 60%;
  }
  .content:last-child {
    width: 40%;
    border-left: 1px solid #c0c3c7;
    padding-left: 30px;
  }
  .btn-bleu a {
    color: $white;
  }
}

.home-emploi-annonce {
  @extend %shadow;
  background-color: $white;
}

.bck-emploi {
  background-image: url("../images/illustration_dent.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}
.bck-annonce {
  background-image: url("../images/illustration_achats.jpg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}

.home-emploi-titre {
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;

  span {
    align-self: center;
  }
  button,
  a {
    align-self: center;
  }
}

.home-emploi-list {
  width: 75%;
}

.ads-home-center {
  padding-left: 10px;
  padding-right: 10px;

  img {
    width: 100%;
  }
}

.formation {
  display: flex;
  margin: 0;
  padding-bottom: 1.5rem;

  .formation-left {
    display: flex;
    flex-wrap: wrap;
    width: 66%;
    padding: 1.5rem;
    margin: 0;
    background-color: $gris-clair;
    border-radius: 7px;

    a {
      width: 50%;
    }
  }

  .formation-right {
    display: flex;
    width: 34%;
    padding: 0 0 0 0.5rem;
    margin: 0;

    li {
      font-size: 0.8rem;
      font-weight: bold;
    }

    li > span {
      font-weight: normal !important;
      font-size: 0.7rem !important;
    }

    .titre-cat {
      color: $bleu-ciel;
      font-weight: bold;
    }

    .plus > li {
      font-weight: normal !important;
      text-decoration: underline;
    }

    .a-und {
      font-size: 0.7rem;
    }
  }
}

.formation-col-cat {
  width: 100%;
  background-color: $gris-clair;
  border-radius: 7px;
  padding: 15px 15px 15px 15px;

  li > a > span {
    font-weight: normal !important;
    font-size: 0.7rem !important;
  }

  li {
    padding-left: 10px;
  }

  a {
    font-size: 0.8rem;
    font-weight: bold;
  }

  .plus {
    width: 100%;
    text-align: right;
    padding-right: 15px;
  }

  .plus > a {
    text-decoration: underline;
    font-weight: normal;
  }

  .cartouche {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
}

.a-und,
.a-und:hover {
  text-decoration: underline;
}

.cartouche-date {
  display: flex;
  //flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;

  span {
    font-size: 0.9rem;
    display: block;
    line-height: 1.2rem;
  }
}

.arrow-date {
  @extend %shadow;
  align-items: center;
  background-color: $bleu-fonce;
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  color: $white;
  padding-bottom: 3px;
  padding-right: 27px;
  padding-top: 3px;
  max-width: 115px;
  span {
    display: block;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }
  .day {
    font-size: 2.3rem !important;
    line-height: 2.3rem;
  }
  .month {
    font-size: 0.7rem !important;
    line-height: 1rem;
  }
  .year {
    font-size: 1rem !important;
    line-height: 1rem;
  }
}

.arrow-bg-bleu {
  background-color: $bleu-ciel;
}

.bloc-cartouche {
  height: 100%;
  border-radius: 7px;
  background-color: #f1f3f8;
}

.cartouche-bleu-ciel {
  display: block;
  text-align: center;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: $white;
  background-color: $bleu-ciel;
  font-weight: bold;
  line-height: 2.5rem;
  width: 100%;
}

.event-a-venir {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.col-event {
  height: 100%;
  background-color: $gris-clair;
  border-radius: 7px;
  width: 33%;
  align-self: stretch;

  a {
    margin-bottom: 1rem;
    display: block;
    width: 100%;
  }

  .home-event-titre {
    display: block;
    text-align: center;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: $white;
    background-color: $bleu-fonce;
    margin-bottom: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
    width: 100%;
  }
}

.pill-search.event {
  line-height: 1.5rem;
  font-size: 0.7rem;
  padding: 0 8px;
  margin: 0 3px;
}

.col-event.tab-pane {
  width: 100%;
}

.bloc-company {

  .btn-bleu {
    background: $bleu-fonce;
    font-size: 1rem;
    line-height: 55px;
    max-height: 55px;
    text-align: left;
    transition: color .25s ease-in-out;
  }

  .btn-bleu:hover {
    color: #ffffff;
  }

  strong {
    text-decoration: underline;
  }
}
  

.bloc-newsletter {
  background-image: url("../images/background-newsletter.jpg");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  margin: 1rem 1% 1.5rem 1%;
  padding: 20px;
  border-radius: 7px;
  color: $white;
  position: relative;
  background-size: 100% 100%;
  ul {
    margin-top: 1.5rem;
  }
  li {
    content: "";
    margin-left: 0px;
    background: url("../images/arrow-bleu-ciel.png") no-repeat left;
  }
  li > a {
    padding-left: 30px;
  }
  input {
    padding-left: 20px;
    padding-right: 20px;
    width: 270px;
    align-self: center;
    margin-right: 10px;
  }
  button {
    align-self: center;
  }
  form {
    display: flex;
    float: right;
  }
  span {
    display: block;
    width: 368px;
    float: right;
    font-weight: bold;
    padding-bottom: 0.5rem;
  }

  .part-1 {
    width: 50%;
    padding: 1rem 3rem;
    @media (min-width: 1024px) {
      div {
        width: 50%;
        float: right;
      }
    }
  }
  .part-1-img {
    span {
      width: 50%;
      float: none !important;
      text-align: center;
      margin: 0 auto;
    }
    strong {
      white-space: nowrap;
      word-wrap: break-word;
    }
    img {
      width: 50%;
      margin: 0 auto;
    }
    @media (max-width: 425px) {
      span {
        width: 90%;
        float: none !important;
        text-align: center;
        margin: 0 auto;
      }
      img {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  .part-2 {
    width: 50%;
    border-left: 1px solid #c0c6d1;
    padding: 1rem 3rem;
    form {
      display: flex;
      float: none !important;
    }
    span {
      padding-bottom: 30px;
      float: none !important;
    }
    @media (max-width: 425px) {
      p {
        text-align: justify;
      }
    }
    #results {
      padding-top: 30px;
    }
  }
  a,
  a:hover {
    color: $white;
  }
}

.home-annuaire-papier {
  @extend %width-content;
  display: flex;
  margin: 0 auto;
  padding-top: 0.5rem;

  .btn-bleu {
    a {
      color: $white;
    }
  }

  .partie-1 {
    width: 60%;
    font-size: 1rem;

    .content > div {
      width: 50%;
    }

    .content > div:last-child {
      padding: 0 1rem;
    }

    .title {
      font-weight: bold;
      font-size: 1.2rem;
      display: block;
      padding: 0 0 1rem 0;
    }
  }

  .partie-2 {
    width: 40%;
    border-left: 1px solid $gris-clair;

    .title {
      font-weight: bold;
      font-size: 1.2rem;
      display: block;
      padding: 0 0 1rem 0;
      text-align: center;
    }

    button {
      margin: 0 auto;
    }

    .content > div {
      width: 50%;
    }
  }

  .content {
    display: flex;
    width: 100%;
  }
}

.home-partenaire {
  font-size: 1.2rem;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

/* Register */
.container-register {
  .register-img-right-cd {
    background-image: url("../images/inscription.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 30%;
  }

  .register-img-right-lp {
    background-image: url("../images/inscription.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 30%;
  }

  .register-img-right-fd {
    background-image: url("../images/inscription.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 30%;
  }

  .registrer-block-left {
    @extend %deg-bg-bleu-ciel;
    padding: 50px 4% 50px 5%;
    width: 70%;
  }
}

/* End registrer */

.container-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem auto;
}
.autrePA {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: inherit;
}
.card-list {
  @extend %shadow;
  @extend .card;
  //margin: 1%;
  margin: 0 0 2rem 0;
  width: 29%;
  border: none;
  box-sizing: border-box;
}

.flag-stand{
  position: absolute;
  background-color: #25aae1;
  padding: 0.5rem;
  color: #FFF;
  font-size: .8rem;
  border-top-left-radius: 4px;
}

.card-list-pro {
  @extend .card-list;
}

.card-list-mp {
  @extend %shadow;
  @extend .card;
  //margin: 1%;
  margin: 0 0 2rem 0;
  width: 22%;
  border: none;
  box-sizing: border-box;
  font-size: .8rem;

  .card-title {
    height: 1rem;
    font-size: .9rem;
  }

  p {
    margin-bottom: .5rem;
  }
}

.card-anden {
  .card-title {
    height: 25px;
  }
}

.card-participation {
  @extend .card-list;
  width: 48%;

  .row {
    margin: 0;
    align-items:center;
  }

  p {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .applat-bleu {
    background-color: $bleu-ciel;
    color: $white;
    padding: 0 .5rem;
    text-align: center;
  }
}

.card-dummy {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Card */
.card-title {
  font-size: 0.8rem;
  height: 2rem;
}

.card-text {
  font-size: 0.8rem;
}

.card-date {
  font-size: 0.7rem;
}

.card img {
  max-width: 100%;
  max-height: 100%;
}

.card-login {
  @extend %shadow;
  border-radius: 7px;
  padding: 15px;
  .login-title{
    background-color: #2f3944; 
    color: #ffffff; 
    padding: 10px; 
    font-weight: bold;
  }
}
.card-login > p{
  padding: 10px; 
  margin-top: 20px;
}

/* End card */

.block-filter,
.block-filter:hover {
  background-color: $bleu-fonce;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 7px 7px 0 0;
  display: block;
  color: $white;
  position:relative;
  cursor:pointer;
}

a.block-filter::after{
  content:'';
  background: url("../images/arrow-select-search-down.png") no-repeat;
  width: 13px;
  height:8px;
  position:absolute;
  top:18px;
  right:24px;
  transition: transform 0.2s ease-in-out;
  
}

a.rotate.block-filter::after{
  transform: rotate(180deg);
}

.tri-alpha {
  span {
    color: $bleu-le;
    padding-left: 5px;
  }
  a {
    color: $white;
    margin: 0 0.15rem;
    font-weight: normal;
  }
  a:hover {
    text-decoration: underline;
    color: $bleu-le;
  }
}

.card-filter {
  border-radius: 0 0 7px 7px;
  background-color: $gris-clair;
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;

  //div {
  //  width: 30%;
  //  border-left: 1px solid $gris;
  //  padding-left: 1rem;
  //
  //  span {
  //    font-weight: bold;
  //  }
  //}

  span, .label-geo {
    font-weight: bold;
  }

  select {
    font-size: .75rem;
  }

  .form-group {
    margin-bottom: .4rem;
  }

  .col-right-filter{
    border-left: 1px solid;
  }

  .submit {
    width: 100%;
    border: none;
    padding-top: .5rem;

    button {
      margin: 0 auto;
    }
  }
}

/* Bloc search by */
.block-search-by {
  @extend %shadow;
  padding: 30px;
  font-size: 0.8rem;

  h2 {
    color: $gris;
    padding-bottom: 20px;
  }

  div {
    column-count: 4;
  }
  a {
    width: auto;
    display: block;
  }
  a:hover {
    text-decoration: underline;
  }

  .search-form {
    text-align: left;
    column-count: unset;
    h3,h2 {
      width: 100%;
      text-align: left;
      padding-left: 30px;
    }
    input {
      padding: 0 5px;
      margin-left: 30px;
      margin-right: 10px;
      border: 1px solid $gris-clair;
    }
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    .algolia-autocomplete {
      display: unset !important;
    }
  }

}

.input-search-home-produit, .input-search-home-produit:focus {
  padding: 0 5px !important;
  margin-left: 0px;
  margin-right: 10px;
  border: 1px solid $gris-clair;
}


/* End  bloc search by */

#accordion-produits-d {
  h2 {
    padding: 30px 30px 0 30px;
    position: relative;
    overflow: hidden;
  }

  #collapseItems {
    padding: 30px;
  }
}

.wrap {
  //@extend %width-content;
  display: flex;
  max-width: $max-content-width;
  margin: 0 auto;
  padding-left: min(5%);
  padding-right: min(5%);
}

.container-register {
  display: flex;
}

.main-content {
  width: 100%;
  padding-right: 50px;

  .link-return {
    font-size: 0.85rem;
    color: #414d5a;
    transition: color .25s ease-in-out;
  }

  .link-return:hover {
    color: #637488;
  }
}

.fiche-cd {
  .banners {
    margin-bottom: 20px;
  }
}

#pills-particulier {
  ul > li {
    list-style: disc;
    margin-left: 25px;
    padding-left: 0;
    vertical-align: middle;
  }

  ul > li::marker {
    color: $bleu-ciel;
  }

  .fiche-block-description {
    box-shadow: unset !important;
  }
}


.text-seo{
  font-size: 14px;
  .content{
    /* min-height: 220px; */ /*max-height: 160px; overflow: hidden; */ 
    margin-bottom: 20px; text-align: justify;
  }
  .overlay{
    background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(255,255,255,0.95) 90%); height: 220px; position: relative; margin-top: -240px;
  }
  .content.opened ~ .overlay{display:none}
}

.text-recherche{
  .content{
    height: 65px;max-height: 65px; 
  }
  .content.opened {
    height: unset;
  }
  .overlay{
    background: linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 75%, rgba(255,255,255,0.95) 90%);
  }
}

.sidebar {
  width: 310px;
  min-width: 310px;

  .bloc-gris-sidebar {
    padding: 17px;
    border-radius: 7px;
    background-color: $background-bleu-clair;
    margin-bottom: 17px;
    text-align: center;
  }

  .bloc-pub-sidebar {
    // padding: 17px;
    border-radius: 7px;
    //background-color: $background-bleu-clair;
    margin-bottom: 17px;
    text-align: center;
  }

  h3 {
    font-size: 1.125rem;
  }

  .annuaire-bg-sidebar {
    background-image: url("../images/sidebar-annuaire-bg.png");
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 150px;
    font-size: 0.875rem;
  }

  .bloc-newsletter-sidebar {
    background-color: $bleu-nuit;
    color: $white;
    border-radius: 7px;
    padding: 35px;
    margin-bottom: 17px;
    text-align: center;

    hr {
      border-top: 1px solid $white;
    }

    h3 {
      color: $white;
    }

    input {
      width: 100%;
      padding: 0 20px;
    }

    a {
      color: $white;
    }
  }

  .bloc-info-sidebar {
    background-color: $bleu-ciel;
    color: $white;
    text-align: center;
    border-radius: 7px;
    padding: 17px;
    margin-bottom: 17px;

    h3 {
      color: $white;
    }

    a,
    a:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.ad-map-cd,
.ad-map-lp,
.ad-map-fd {
  padding: 1rem 0 3rem 0;
  a:hover > path,
  a:hover > polygon {
    fill: #f26522;
  }

  .list-map {
    display: flex;
  }

  .map {
    width: 40%;
    padding: 0 2rem 0rem 0rem;
  }

  .list {
    @extend %shadow;
    display: block;
    width: 60%;
    padding: 20px;
    font-size: 0.8rem;

    div {
      column-count: 3;
      ul li a:hover {
        color: #ef5d18;
      }
    }
  }
}

.ad-map-lp,
.ad-map-fd {
  display: none;
}

.list-categorie {
  display: flex;
  padding: 0 2% 2rem 2%;
  div {
    width: 50%;
  }
  span {
    display: block;
    margin-bottom: 2rem;
  }
  ul {
    list-style: disc;
    margin-left: 1.5rem;
    li a:hover {
      color: #ef5d18;
    }
  }
}

.text-2-col {
  display: flex;
  div {
    width: 50%;
    padding: 0 2rem;
  }
}

.block-contexte {
  background-color: $gris;
  border-radius: 7px;
  font-size: 0.8rem;
  margin: 0;
  a,
  a:hover {
    color: $white;
    line-height: 2rem;
  }

  .return {
    width: 50%;
  }

  .previous {
    background-image: url("../images/arrow-left-white.svg");
    background-position: left;
    background-repeat: no-repeat;
    padding-left: 2rem;
    margin-left: 2rem;
    width: 20%;
  }

  .next {
    background-image: url("../images/arrow-right-white.svg");
    background-position: right;
    background-repeat: no-repeat;
    padding-right: 2rem;
    margin-right: 2rem;
    width: 20%;
  }

  .next-prev-none {
    width: 25%;
  }
}

.badge {
  vertical-align: middle;
  background-color: $bleu-nuit;
  border-radius: 0;
  font-size: 60%;
}

.block-gris {
  width: 100%;
  border-radius: 7px;
  background-color: $gris-clair;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.event-portes-ouvertes,
.home-item-produit {
  @extend %shadow;

  .card-img-top {
    min-height: 200px;
    object-fit: cover;
    margin: 0;
    width: initial;
  }

  .date {
    font-size: 13px;
  }

  .card-body img {
    margin: 0;
    width: auto;
    height: auto;
  }
}

.img-vich-preview {
  max-width: 250px;
  max-height: 250px;
  border: 1px solid $gris-clair;
}

.alert a {
  color: #721c24 !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}

.card:hover, .card-list:hover, .card-list-mp:hover, .card-horyzontal:hover {
  cursor: pointer;
}

.mode-client {
  display: block;
  width: 100%;
  background-color: $orange;
  font-weight: bold;
  text-decoration: underline;
  color: $white;
  padding-left: 10px;
}

#map {
  height: 300px;
  border-radius: 7px;
}

.colum-radio {
  column-count: 3;
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .bck-annonce,
  .bck-emploi {
    background-image: none;
  }

  .home-emploi-list {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  h2 {
    font-size: 1rem;
  }

  h3 {
    font-size: 0.9rem;
    font-weight: bold;
  }

  p {
    font-size: 0.85rem;
  }

  %width-content {
    padding-left: 2%;
    padding-right: 2%;
  }

  %width-full-content {
    padding-left: 2%;
    padding-right: 2%;
  }

  .in-width-full-content {
    max-width: $max-content-width;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    height: 100%
  }

  .home-baseline {
    @extend %width-full-content;
    flex-wrap: wrap;

    h1 {
      font-size: 0.8rem;
      text-align: center;
      padding: 1rem 0.5rem;

      span {
        color: $bleu-ciel;
        display: block;
        font-weight: 600;
      }
    }

    .baseline-left {
      width: 15%;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      h2 {
        color: $white;
        margin-top: 0;
      }
    }

    .baseline-right {
      width: 85%;
      display: flex;
      align-items: stretch;

      .card-home {
        @extend %shadow;
        border-radius: 7px;
        max-width: 260px;
        text-align: center;

        h3 {
          font-weight: bold;
          display: block;
          line-height: 1.25rem;
          height: 2.5rem;
        }

        a {
          line-height: 2rem;
          display: inline-block;
          margin-top: 10px;
        }
      }
    }
  }

  .produit-une {
    padding-top: 0;
    padding-bottom: 0;
  }

  .plus-produit-une {
    text-align: center;
  }

  .search-form {
    text-align: center;
    .algolia-autocomplete {
      display: unset !important;
    }
  }

  .home-input-search,
  .home-input-search:focus {
    border: 0;
    border-radius: 7px;
    box-shadow: none;
    line-height: 40px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    vertical-align: baseline !important;
  }

  .home-select-search {
    color: $white;
    border: 0;
    border-radius: 7px;
    line-height: 40px;
    height: 42px;
    outline: none;
    margin: 10px 0;
    padding: 0 20px;
    width: 100%;
    background: url("../images/arrow-select-search-down.png") no-repeat $gris;
    background-position: right 20px top 16px;
    -webkit-appearance: none;
  }

  .input-search,
  .input-search:focus {
    border: 0;
    border-radius: 7px;
    box-shadow: none;
    line-height: 40px;
    padding: 0 20px 0 20px;
    margin-bottom: 20px;
    width: 100%;
  }

  .btn-bleu-search {
    margin: 0 auto;
  }

  .home-search-spe {
    flex-wrap: wrap;
    div {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  .title-gris {
    border-radius: 0px;
    margin: 0px;
    padding-left: 2%;
  }

  .main-content {
    padding-right: 0;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .block-search-by {

    margin-bottom: 3rem;
  }

  .container-register {
    flex-wrap: wrap;

    .register-img-right-cd {
      width: 0px;
      display: none;
    }

    .registrer-block-left {
      @extend %deg-bg-bleu-ciel;
      padding: 50px 4% 50px 5%;
      width: 100%;
    }
  }

  .sidebar {
    width: 100%;

    .no-bck-mobile {
      background: none;
    }
  }

  // .ban-slider {
  //   display: none;
  // }

  .bloc-gris-sidebar .ban-slider .bloc-pub-sidebar  {
    display: block;
  }

  .bloc-newsletter {
    background-image: url("../images/background-newsletter.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    flex-wrap: wrap;
    margin: 1rem 1% 1.5rem 1%;
    padding: 10px;
    color: $white;

    ul {
      margin-top: 1rem;
    }

    input {
      padding-left: 10px;
      padding-right: 10px;
      margin: 0 0 1rem 0;
      width: 100%;
    }

    button {
      margin: 0 auto;
    }

    form {
      flex-wrap: wrap;
      width: 100%;
      text-align: center;
    }

    span {
      width: 100%;
      text-align: left;
      font-weight: bold;
      padding-bottom: 1rem;
    }

    .part-1 {
      width: 100%;
      padding: 0 0 1rem 0;
      margin: 1rem 1.5rem 0 1.5rem;
      border-bottom: 1px solid $white;
    }
    .part-2 {
      width: 100%;
      border: none;
      padding: 0;
      margin: 1rem 1.5rem;
    }

    a,
    a:hover {
      color: $white;
      font-size: 0.8rem;
    }
  }

  .home-annuaire-papier {
    @extend %width-content;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding-top: 0.5rem;

    .partie-1 {
      width: 100%;
      font-size: 0.8rem;

      img {
        max-width: 275px;
      }

      button {
        margin: 0 auto;
      }

      .content > div {
        width: 100%;
        margin-bottom: 1rem;
        text-align: center;
      }

      .content > div:last-child {
        padding: 0 1rem 2rem 0;
        margin-bottom: 2rem;
        border-bottom: 1px solid $gris-clair;
      }
    }

    .partie-2 {
      width: 100%;
      border-left: 1px solid $gris-clair;

      .content > div {
        width: 50%;
      }

      img {
        max-width: 200px;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .title {
      font-weight: bold;
      font-size: 1.2rem;
      display: block;
      padding: 0 0 1rem 0;
      text-align: center;
    }
  }

  .formation {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding-bottom: 1.5rem;

    .formation-left {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0.5rem 0.5rem 1.5rem 0.5rem;
      margin: 0;
      background-color: $gris-clair;
      border-radius: 0;

      span {
        display: block;
        font-size: 0.9rem;
      }

      a {
        width: 100%;
      }
    }

    .formation-right {
      display: flex;
      width: 100%;
      padding: 0 0 0 0.5rem;
      margin: 0;
    }
  }

  .cartouche-date {
    width: 100%;
    padding-bottom: 1rem;
  }

  .event-a-venir {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: space-between;
    align-items: stretch;*/
  }

  .col-event {
    height: 100%;
    background-color: $gris-clair;
    border-radius: 7px;
    width: 100%;
  }

  .list-btn-recherche-produit {
    p {
      padding: 0 20px;
    }
  }

  .home-block-info {
    margin: 0;
    /*padding-left: 5%;
    padding-right: 5%*/
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.8rem;
    display: flex;
    flex-wrap: wrap;

    .content {
      width: 100%;
      margin-left: 2% !important;
      margin-right: 2% !important;
    }
    .content:first-child {
      margin-bottom: 2rem;
    }

    ul {
      column-count: 1;
      font-size: 0.75rem;
    }
  }

  .home-block-organisme {
    @extend %shadow;
    background-color: $white;
    margin: 30px 2% 0 2%;
    background-image: none;
    padding: 1.5rem;
    width: 100%;
    flex-wrap: wrap;

    .content {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .content:first-child {
      width: 100%;
      margin-bottom: 0;
    }
    .content:last-child {
      width: 100%;
      border-left: none;
      border-top: 1px solid #c0c3c7;
      padding: 1rem 0 0 0;
    }

    ul {
      column-count: 1;
    }
  }

  .block-contexte {
    background-color: $gris;
    border-radius: 7px;
    font-size: 0.8rem;
    margin: 0;
    a,
    a:hover {
      color: $white;
      line-height: 2rem;
    }

    .return {
      width: 100%;
    }

    .previous {
      background-image: url("../images/arrow-left-white.svg");
      background-position: left;
      background-repeat: no-repeat;
      padding-left: 2rem;
      margin: auto;
      width: auto;
    }

    .next {
      background-image: url("../images/arrow-right-white.svg");
      background-position: right;
      background-repeat: no-repeat;
      padding-right: 2rem;
      margin: auto;
      width: auto;
    }

    .next-prev-none {
      width: 100%;
    }
  }

  .home-titre {
    font-size: 1rem;
    padding: 0.5rem 2%;
  }

  .block-orange,
  .block-rouge,
  .block-bleu {
    margin-bottom: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .list-categorie {
    display: flex;
    flex-wrap: wrap;
    padding: 0 2% 1rem 2%;
    div {
      width: 100%;
    }
    span {
      display: block;
      margin-bottom: 1rem;
    }
    //ul {
    //  list-style: disc;
    //  margin-left: 1.5rem;
    //}
    ul li a:hover {
      color: #ef5d18;
    }
  }

  .pill-search {
    /*line-height: 2rem;
    border-radius: 2rem;
    border: none;
    padding: 0 20px 0 20px;
    background-color: $white;
    font-weight: bold;*/
    margin: 0.5rem;
  }

  .ad-map-cd,
  .ad-map-lp,
  .ad-map-fd {
    padding: 1rem 2% 3rem 2%;
    a:hover > path,
    a:hover > polygon {
      fill: #f26522;
    }

    .list-map {
      display: flex;
      flex-wrap: wrap;
    }

    .map {
      width: 100%;
      padding: 1rem;
    }

    .list {
      @extend %shadow;
      display: block;
      width: 100%;
      padding: 20px;
      font-size: 0.8rem;

      div {
        column-count: 1;
      }
    }
  }

  .text-2-col {
    padding: 0 2%;
    display: flex;
    flex-wrap: wrap;

    div {
      width: 100%;
      padding: 0 1rem;
    }
  }

  .container-list {
    //justify-content: center;
    justify-content: space-between;
  }

  .card-list {
    width: 30%;
  }


  .card-list-mp {
    width: 100%;
  }

  .carousel-control-prev {
    margin-left: 5px;
  }

  .carousel-control-next {
    margin-right: 5px;
  }

  .card-filter {
    .col-right-filter{
      border-left: none;
    }
  }

  .colum-radio{
    column-count: 1;
  }

}

// @media (min-width: 768px) {
//   .card img {
//     height: 11em;
//   }
// }


@media (max-width: 992px) {
  .row.events {
    margin: 0;
  }
}

@media (max-width: 1294px) and (min-width: 768px) {
  .home-baseline .baseline-right .card-home.cd .bkg-img, .home-baseline .baseline-right .card-home.lp .bkg-img, .home-baseline .baseline-right .card-home.fd .bkg-img {background-size: 160%;}
}

@media (min-width: 768px) {
  .card-three {
    .card-title {
      font-size: 1.3rem;
    }

    .pill-search {
      font-size: 0.8rem;
      line-height: 1.4rem;
      padding: 0 15px;
    }
  }
  
}

@media (max-width: 767px) {
  .ban-billboard-top {
    display:none;
  }
}

@media (max-width: 768px) {
  .block-search-by {
    div {
      column-count: auto;
    }
  }

  #accordion-produits-d {
    h2 {
      padding: 20px 60px 20px 30px;
      color: $white;
      background-color: $bleu-fonce;
      cursor: pointer;
      border-radius: 4px 4px 0 0;
      margin-bottom: 0 !important;
    }

    h2::before {
      content: '';
      position: absolute;
      background-image: url("../images/arrow-right-white.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 35px;
      height: 35px;
      right: 10px;
      top: calc((100% / 2) - 22px);
      transform: rotate(90deg);
      transition: transform .25s ease-in-out;
    }

    h2.collapsed::before {
      transform: unset;
    }
  
    #collapseItems {
      padding-top: 30px;
      background-color: #f1f3f8;
      border-radius: 0 0 4px 4px;
    }
  }

  .acc-list {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .acc-list:nth-child(2) .accordion {
    box-shadow: 0px 13px 15px 1px rgba(47, 57, 68, 0.2);
  }

  // .ban-billboard-top {
  //   display:none;
  // }
  .container-list {
    justify-content: center;
  }

  .card-list {
    width: 100%;
  }

  .home-3-btn {
    height: auto;
    padding: 2% 7% 2% 7%;
  }

  .home-baseline .baseline-left {
    margin: 10px 0;

    h2 {
      margin-bottom: 0;
    }
  }

  .home-baseline {
    .baseline-left, .baseline-right {
      width: 100%;
    }

    .baseline-right .card-home {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
      height: auto;
  
      .bkg-img {
        height: 100px;
        background-position: top 25% center !important;
      }
  
      h3 {
        height: 2rem;
      }
  
      a {
        margin-top: 0;
      }
    }

    .baseline-right .card-home.cd .bkg-img, .baseline-right .card-home.lp .bkg-img, .baseline-right .card-home.fd .bkg-img  {
      background-size: cover;
    }
  }

  .card-img-overlay {
    justify-content: center !important;
  }

  .list-btn-recherche-produit .card-three {
    margin-top: 0 !important;
  }

  .list-btn-recherche-produit .card-two .card, .list-btn-recherche-produit .card-three .card {
    width: 100%;
  }

  .list-btn-recherche-produit .card {
    margin-right: 0 !important;
    margin-bottom: 20px !important;
    max-height: 220px;
    height: 100%;
  }

  .list-btn-recherche-produit .card-title {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 600px) {
  .hide-mobile-600 {
    display: none;
  }
}
@media (max-width: 700px) {
  .hide-mobile-700 {
    display: none;
  }
}

@media (max-width: 425px) {

  .tab-pane {
    .titre-categorie {
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }

    .row {
      box-shadow: 0 0 15px 1px rgb(47 57 68 / 20%);

      .col-md-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .accordion {
          box-shadow: none;

          .card:last-of-type {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .col-event.tab-pane {
    padding-left: 20px !important;
    padding-right: 20px !important;

    .row {
      box-shadow: none;
    }
  }

  #carousel-produit-une,
  #carousel-portes-ouvertes,
  #carousel-produit-nouveau {
    .slider-product {
      margin-left: 14%;
      margin-right: 14%;
    }
  }
}

/* Bootstrap 4 Multiple Item Carousel */
#carousel-produit-une,
#carousel-portes-ouvertes,
#carousel-produit-nouveau {
  @media (max-width: 768px) {
    .carousel-inner .carousel-item > div {
      display: none;
    }

    .carousel-inner .carousel-item > div:first-child {
      display: block;
    }
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  /* medium - display 2  */
  @media (min-width: 768px) {
    .carousel-inner .carousel-item-right.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(50%);
    }

    .carousel-inner .carousel-item-left.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-50%);
    }
  }

  /* large - display 4 */
  @media (min-width: 992px) {
    .carousel-inner .carousel-item-right.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(25%);
    }

    .carousel-inner .carousel-item-left.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-25%);
    }
  }

  .carousel-inner .carousel-item-right,
  .carousel-inner .carousel-item-left {
    transform: translateX(0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  background-color: #8d97a2;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  .carousel-control-prev-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .carousel-control-next-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* start css for slick */

.slider {
  width: 100%;
  margin: 0 auto;
  .card-img-top {
    min-height: 200px;
    object-fit: cover;
    margin: 0;
    width: initial;
  }
  .card-body {
    width: 100%;
    height: 150px !important;
    text-align: center;
  }
}

.slick-slide {
  margin: 0px 20px;
}
.slick-list {
  width: 100%;
  overflow: visible;
}

.slick-slide div {
  width: 100%;
}

.slick-prev:before,
.slick-next:before {
  color: #8d97a2;
}

.slick-slide {
  transition: all ease-in-out 0.3s;
  opacity: 1;
}

.slick-active {
  opacity: 1;
}

.slick-current {
  opacity: 1;
}

.slick-prev,
.slick-next {
  width: 40px;
}
.slick-prev {
  left: -40px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #8d97a2;
  font-size: 40px;
}

.produit-une-img {
  height: 250px !important;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .slider {
    width: 80%;
    margin: 0 auto;
  }

  .slick-slide {
    margin: 10px 10px;
  }

  .slick-slide div {
    width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #8d97a2;
    font-size: 40px;
  }

  .slick-slide {
    transition: all ease-in-out 0.3s;
    opacity: 0.2;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-current {
    opacity: 1;
  }

  .produit-une-img {
    height: 350px !important;
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .ban-parallax-top {
    display:none;
  }

  .slider {
    width: 80%;
    margin: 0 auto;
  }

  .slick-slide {
    margin: 10px 10px;
  }

  .slick-slide div {
    width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #8d97a2;
    font-size: 40px;
  }

  .slick-slide {
    transition: all ease-in-out 0.3s;
    opacity: 1;
  }

  .slick-active {
    opacity: 1;
  }

  .slick-current {
    opacity: 1;
  }
  .slick-prev {
    left: -50px !important;
  }
  .slick-next {
    right: -50px !important;
  }
}

/* end of css for slick */

/* start css for petites-annonces */

.petites-annonces {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  justify-self: center;
  min-height: 60px;
  margin: 1rem;
  .block {
    border-radius: 4px;
    -webkit-box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
    box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 10px;
    align-items: center;
    align-content: center;
    .bloc-2 {
      text-align: right;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    img {
      display: grid;
      align-items: center;
      text-align: left;
      width: 80%;
    }

    .select-boxs {
      width: 100%;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      display: grid;
      grid-template-rows: 40px 20% 20% 20% 20%;
      gap: 10px;
      align-content: center;
      align-items: center;

      .select2-selection__rendered,
      .select2-selection__placeholder {
        color: #000 !important  ;
        line-height: 28px;
        text-align: center;
      }
      .select2-container--default .select2-selection--single {
        background-color: #f1f3f8;
        border: none;
        border-radius: 4px;
        height: 100%;
      }
      .select2-selection__rendered {
        line-height: 31px !important;
      }
      .select2-container .select2-selection--single {
        height: 35px !important;
      }
      .select2-selection__arrow {
        height: 34px !important;
      }
      .btn-recherche {
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: 50% 50%;
        //gap: 10px;
        justify-content: center;
        align-items: center;
        .btn-bleu {
          width: 80%;
          box-shadow: none;
        }
        .pt-lien-gris {
          text-align: right;
        }
      }
    }
    .annonce {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      display: grid;
      grid-template-rows: 40px auto 50px;
      overflow: hidden;
      .btn-annonce {
        width: 75%;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 1.5rem;
    margin: 1rem;
    grid-template-columns: 100%;
    grid-template-rows: 55% 50%;
    gap: 10px;
    .block {
      border-radius: 4px;
      -webkit-box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
      box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
      display: grid;
      grid-template-columns: 60% 40%;
      gap: 10px;
      align-items: center;
      align-content: center;
      .bloc-2 {
        text-align: right;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
      img {
        display: grid;
        align-items: center;
        text-align: left;
      }

      .select-boxs {
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        display: grid;
        grid-template-rows: 40px 20% 20% 20% 20%;
        gap: 10px;
        align-content: center;
        align-items: center;

        .select2-selection__rendered,
        .select2-selection__placeholder {
          color: #000 !important  ;
          line-height: 28px;
          text-align: center;
        }
        .select2-container--default .select2-selection--single {
          background-color: #f1f3f8;
          border: none;
          border-radius: 4px;
          height: 100%;
        }
        .select2-selection__rendered {
          line-height: 31px !important;
        }
        .select2-container .select2-selection--single {
          height: 35px !important;
        }
        .select2-selection__arrow {
          height: 34px !important;
        }
        .btn-recherche {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          .btn-bleu {
            width: 60%;
            box-shadow: none;
          }
        }
      }
      .annonce {
        grid-template-rows: 40px auto 50px;

        overflow: hidden;
        .btn-annonce {
          width: 60%;
        }
      }
    }
  }
  @media (max-width: 425px) {
    margin: 1rem;
    padding: 0;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    gap: 10px;
    .block {
      grid-template-columns: 1fr;
      align-items: center;
      align-content: center;
      .bloc-2 {
        padding-top: 0;
        text-align: center;
      }
      img {
        display: none;
      }
      .select-boxs {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        display: grid;
        grid-template-rows: 40px 20% 20% 20% 20%;
        gap: 10px;
        align-content: center;
        align-items: center;
        .select2-selection__rendered,
        .select2-selection__placeholder {
          color: #000 !important  ;
          line-height: 28px;
          text-align: center;
        }
        .select2-container--default .select2-selection--single {
          background-color: #f1f3f8;
          border: none;
          border-radius: 4px;
          height: 100%;
        }
        .select2-selection__rendered {
          line-height: 31px !important;
        }
        .select2-container .select2-selection--single {
          height: 35px !important;
        }
        .select2-selection__arrow {
          height: 34px !important;
        }
        .btn-recherche {
          width: 100%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          .btn-bleu {
            width: 60%;
            box-shadow: none;
          }
        }
      }
      .annonce {
        grid-template-rows: 40px auto 50px;
        padding: 20px;
        overflow: hidden;
        text-align: justify;
        .btn-annonce {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
}

/*end css for petites-annonces*/

/*strat css for offres-emploi*/
.offres-emploi {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 2%;
  justify-self: center;
  min-height: 60px;
  margin: 1rem;
  .annonce-bg {
    background-image: url("../images/illustration_emploi.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 180%;
  }
  .block {
    border-radius: 4px;
    -webkit-box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
    box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 10px;
    align-items: center;
    align-content: center;
    .bloc-2 {
      text-align: right;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
    img {
      display: grid;
      align-items: center;
      text-align: left;
      width: 80%;
    }

    .select-boxs {
      width: 100%;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      display: grid;
      grid-template-rows: 40px 20% 20% 20% 20%;
      gap: 10px;
      align-content: center;
      align-items: center;

      .select2-selection__rendered,
      .select2-selection__placeholder {
        color: #000 !important  ;
        line-height: 28px;
        text-align: center;
      }
      .select2-container--default .select2-selection--single {
        background-color: #f1f3f8;
        border: none;
        border-radius: 4px;
        height: 100%;
      }
      .select2-selection__rendered {
        line-height: 31px !important;
      }
      .select2-container .select2-selection--single {
        height: 35px !important;
      }
      .select2-selection__arrow {
        height: 34px !important;
      }
      .btn-recherche {
        width: 100%;
        height: 50px;
        display: grid;
        grid-template-columns: 50% 50%;
        //gap: 10px;
        justify-content: center;
        align-items: center;
        .btn-bleu {
          width: 80%;
          box-shadow: none;
        }
        .pt-lien-gris {
          text-align: right;
        }
      }
    }
    .annonce {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      display: grid;
      grid-template-rows: 40px auto 50px;
      overflow: hidden;
      .btn-annonce {
        width: 75%;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 1.5rem;
    margin: 1rem;
    grid-template-columns: 100%;
    grid-template-rows: 55% 50%;
    gap: 10px;
    .annonce-bg {
      background-image: url("../images/illustration_emploi.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: right;
    }
    .block {
      border-radius: 4px;
      -webkit-box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
      box-shadow: 0 0 15px 1px rgba(47, 57, 68, 0.2);
      display: grid;
      grid-template-columns: 60% 40%;
      gap: 10px;
      align-items: center;
      align-content: center;
      .bloc-2 {
        text-align: right;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
      img {
        display: grid;
        align-items: center;
      }

      .select-boxs {
        width: 100%;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        display: grid;
        grid-template-rows: 40px 20% 20% 20% 20%;
        gap: 10px;
        align-content: center;
        align-items: center;

        .select2-selection__rendered,
        .select2-selection__placeholder {
          color: #000 !important  ;
          line-height: 28px;
          text-align: center;
        }
        .select2-container--default .select2-selection--single {
          background-color: #f1f3f8;
          border: none;
          border-radius: 4px;
          height: 100%;
        }
        .select2-selection__rendered {
          line-height: 31px !important;
        }
        .select2-container .select2-selection--single {
          height: 35px !important;
        }
        .select2-selection__arrow {
          height: 34px !important;
        }
        .btn-recherche {
          width: 100%;
          height: 50px;
          padding-left: 10px;
          .btn-bleu {
            width: 50%;
            box-shadow: none;
          }
          .pt-lien-gris {
            text-align: right;
          }
        }
      }
      .annonce {
        grid-template-rows: 40px auto 50px;

        overflow: hidden;
        .btn-annonce {
          width: 60%;
        }
      }
    }
  }
  @media (max-width: 425px) {
    margin: 1rem;
    padding: 1.5rem;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    gap: 10px;
    .annonce-bg {
      background-image: none;
    }
    .block {
      grid-template-columns: 1fr;
      align-items: center;
      align-content: center;
      .bloc-2 {
        padding-top: 0;
        text-align: center;
      }
      img {
        display: none;
      }
      .select-boxs {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        display: grid;
        grid-template-rows: 40px 20% 20% 20% 20%;
        gap: 10px;
        align-content: center;
        align-items: center;
        .select2-selection__rendered,
        .select2-selection__placeholder {
          color: #000 !important  ;
          line-height: 28px;
          text-align: center;
        }
        .select2-container--default .select2-selection--single {
          background-color: #f1f3f8;
          border: none;
          border-radius: 4px;
          height: 100%;
        }
        .select2-selection__rendered {
          line-height: 31px !important;
        }
        .select2-container .select2-selection--single {
          height: 35px !important;
        }
        .select2-selection__arrow {
          height: 34px !important;
        }
        .btn-recherche {
          width: 100%;
          height: 50px;
          display: grid;
          grid-template-rows: 50% 50%;
          grid-template-columns: 100%;
          justify-content: center;
          align-items: center;
          justify-items: center;

          .btn-bleu {
            width: 45%;
            box-shadow: none;
          }
        }
      }
      .annonce {
        grid-template-rows: 40px auto 50px;
        padding: 20px;
        overflow: hidden;
        text-align: justify;
        .btn-annonce {
          width: 80%;
          margin: 0 auto;
        }
      }
    }
  }
}

.listPA {
  width: 100%;
  margin: 1em 0;
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
  }
  li {
    list-style: none;
    display: inline-block;
    text-align: center;
  }
  a:hover {
    text-decoration: underline;
  }
  li:last-child > span {
    display: none;
  }
  .separator {
    //font-size: .5rem;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.newsletter-liste {
  color: #3274c6;

  li a {
    color: #3274c6;
  }
}

#news-detail {
  width: 100%;
  height: 100%;
  @media (max-width: 425px) {
    width: 100% !important;
    object-fit: contain !important;
  }
}

.organismes {
  width: 100%;
  .organisme {
    margin-bottom: 0.8rem;
    width: 100%;
    a {
      color: #25aae1;
    }
  }
}

.hide {
  display: none;
}
.btn-hide {
  display: none;
}
