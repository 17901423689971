// button radio
/*
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 3px solid #25a8df;
  border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #ffffff;
}

.control input:checked ~ .control_indicator {
  background: #25a8df;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #25a8df;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 3px;
  top: 3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #ffffff;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}*/
