.fiche-block-gris{
  @extend .row;
  border-radius: 7px;
  background-color: $gris-clair;
  padding: 17px;
  align-items: center;
  margin: 0;

  .pill-fiche {
    line-height: 2rem;
    border-radius: 2rem !important;
    border: none;
    padding: 0 20px 0 20px;
    font-weight: bold;
    margin: .5rem 1rem 1rem 1rem;
  }

  .pill-fiche.active {
    background-color: $bleu-ciel !important;
    color: $white;
  }

  a:first-of-type{
    margin-left: 0;
  }
}

.fiche-block-contact{
  @extend .row;
  @extend %shadow;
  //align-items: center;
  background-color: $white;
  border-radius: 7px;
  padding: 25px;
  margin: 0;
  p {
    font-size: .8rem;
  }
}

.fiche-block-description{
  @extend .row;
  margin: 0;
  p{
    font-size: .8rem;
  }
}

.fiche-cd{

  p {
    font-size: .8rem;
    display: flex;
    margin-bottom: .5rem;
  }

  i {
    margin-right: .8rem;
  }

  .list-competence {
    display: inline-flex;
    flex-wrap: wrap;

    :first-child{
      /*margin-left: 0;*/
    }

    li {
      font-size: .8rem;
      font-weight: normal;
    }
  }

  .tab-content{
    font-size: .8rem;
  }


}
