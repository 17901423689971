.wrap-pro {
  display: flex;
  flex-wrap: nowrap;
  max-width: $max-content-width;
  margin: 0 auto;
  padding-left: min(5%);
  padding-right: min(5%);

  .btn-edit {
    width: 230px;
  }

  .fiche-cd > .custom-file > .custom-file-label {
    display: none !important;
  }

}
.nav-pro {
  display: block;
  min-width: 250px;
  //background-color: #1b9448;

  > ul {
    padding-left: 15px;
    border-left: 2px solid $bleu-ciel;

    > li > a {
      transition: color .25s ease-in-out;
    }

    > li > a:hover, > li a.active {
      color: $bleu-ciel;
    }
  }
}

.block-pro-titre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-list-home-pro {
  @extend .container-list;
  margin-top: 7rem;
}

.card-pro {
  @extend .card;
  @extend %shadow;
  border: none;
  width: 30%;
  margin-bottom: 2rem;

  h5 {
    font-size: 1rem;
    font-weight:bold;
  }

  h5 > a {
    display: flex;
    align-items: center;
  }

  .card-text {
    font-size: .8rem;
  }
}

.flex-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bloc-legale {
  font-size: .8rem;
  background-color: $gris-clair;
  border-radius: 7px;
  padding: 1.5rem;
}

.block-visibilite {
  .block-chiffre {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .txt-libelle {
    font-size: .8rem;
    width: 60%;
    padding-left: 10px;
  }
  .txt-chiffre {
    font-size: 2rem;
    font-weight: bold;
    text-align: right;
    width: 40%;
  }
}

.block-onpc {
  background-color: $bleu-fonce !important;

  h5, a {color: $white;}

  .card-footer {
    background-color: $bleu-fonce !important;
  }
}

@media (max-width: 1024px) {

  .wrap-pro{
    flex-wrap: wrap;

    .btn-gris {
      line-height: 2.2rem;
    }

    button[type=submit]{
      margin: auto;
      margin-bottom: 3rem;
    }

    .vich-image {
      text-align: center;
    }

    .sidebar {
      display: none;
    }
  }

  .nav-pro{
    width: 100%;
    margin-top: 1rem;
  }

  .container-list-home-pro {
    margin-top: 2rem;
  }

  .card-pro {
    width: 90%;
  }

}
