.aa-dropdown-menu{
  width: 100%;
  z-index: 10000 !important;
  column-count: unset !important;
}

[class^="aa-dataset-"]{
  background-color: $white !important;
  display: block;
  width: 100% !important;
  //margin-top: -5px;
  padding: 20px !important;
  color: $bleu-nuit !important;
  border-radius: 0 0 7px 7px;
  column-count: unset !important;
}

.aa-suggestion {
  padding: 6px 12px;
  cursor: pointer;
  column-count: unset !important;
}
