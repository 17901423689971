@import "./variable";

#nav-md {
  min-width: 260px;
  /* border */
  border-right: 1px solid $gris-clair;

  .md-mobile-menu { display: none; }

  .logo {
    display: block;
    height: 50px;
    width: 90%;
    background-image: url("../images/logo-my-dentaire.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .item {
    display: flex;
    align-items: center;
    font-size: .8rem;
    font-weight: bold;
    padding-left: 20px;
    line-height: 40px;
    width: 90%;
    border-left: 4px solid transparent;
    transition: border-left .25s ease-in-out;
    border-radius: 2px;
  }

  .item:hover{
    background-color: $gris-clair;
    border-left: 4px solid $md-vert;
    transition: border-left .25s ease-in-out;
  }

  .item.active {
    border-left: 4px solid $md-vert;
  }

  .item-logout {
    display: flex;
    align-items: center;
    font-size: .8rem;
    color: $bleu-ciel;
    line-height: 35px;
    width: 90%;
    border-radius: 2px;
    padding-left: 20px;
    background-color: $gris-clair;
    font-weight: bold;
    margin-top: 10px;
  }
}

#content-md {
  display: block;
  width: 100%;

  .txt-vert-md {
    color: $md-vert;
  }

  padding-left: min(5%);

  .breadcrumb {
    padding-left: unset !important;
  }

  .logo-md-mobile {
    display: none;
  }

  h1 {
    border-left: 5px solid $md-vert;
    padding-left: 10px;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: unset;
  }

  h2 {
    color: #fff;
    background: $md-gris-fonce;
    border-radius: $md-border-radius;
    font-size: .9rem;
    line-height: 1.8rem;
    padding: 2px 10px;
    text-transform: uppercase;
  }

  h2>a {
    color: white;
    font-size: .8rem;
    float: right;
    text-transform: none !important;
    font-weight: normal !important;
  }

  h2>a:after {
    display: inline-flex;
    width: 15px;
    height: 15px;
    background-color: $md-vert;
    margin-left: 5px;
    vertical-align: -15%;
    content: '';
    border-radius: 10px;
    -webkit-mask: url('../images/md/general-fleche.svg') no-repeat 15% 15%;
    mask: url('../images/md/general-fleche.svg') no-repeat 15% 15%;
    -webkit-mask-size: cover;
    mask-size: cover;
    transform: rotate(-90deg);
  }

  h2>a:hover {
    text-decoration: underline;
  }

  .sub-nav {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
  }

  .bubble {
    height: 50px;
    font-size: .9rem;
    font-weight: bold;
    position: relative;
    border-radius: $md-border-radius;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .bubble-disabled {
    background: #f5f6f7;
    margin-right: 5px;
  }

  .bubble-disabled:before {
    content: "";
    position: absolute;
    right: calc(50% - 10px);
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #f5f6f7 transparent transparent transparent;
  }

  .bubble-enabled {
    background: $md-gris-fonce;
    color: #fff;
    margin-right: 5px;
  }

  .bubble-enabled:before {
    content: "";
    position: absolute;
    right: calc(50% - 10px);
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $md-gris-fonce transparent transparent transparent;
  }

  .bubble-cta {
    background: $md-vert;
    color: #fff;
    margin-left: auto;
  }

  .cta-center {
    margin-right: auto;
    width: fit-content;
  }

  .content {
    background: $md-gris-clair;
    border-radius: $md-border-radius;
    margin: 1.3rem 0;
    padding: 10px;
    box-shadow: 0 0 15px 1px rgb(0 0 0 / 0.2);
  }

  .intro-count {
    font-size: .9rem;
    display: flex;
    align-items: center;

    strong{
      color: $md-vert;
    }

    strong:after, strong:before {
      content: " ";
      white-space: pre;
    }

    .ico {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    .ico-reseau {
      background: url('../images/md/general-groupe.svg') no-repeat;
      background-size: contain;
    }

    .ico-cloche {
      background: url('../images/md/general-notification-1.svg') no-repeat;
      background-size: contain;
    }

    .ico-produit {
      background: url('../images/md/mes-produits-favoris.svg') no-repeat;
      background-size: contain;
    }

    .ico-adresse {
      background: url('../images/md/mon-carnet-d-adresse.svg') no-repeat;
      background-size: contain;
    }

    .ico-evenement {
      background: url('../images/md/mes-evenements.svg') no-repeat;
      background-size: contain;
    }

    .ico-formation {
      background: url('../images/md/mes-formations.svg') no-repeat;
      background-size: contain;
    }

    .ico-emploi {
      background: url('../images/md/mes-offres-d-emploi.svg') no-repeat;
      background-size: contain;
    }

    .ico-annonce {
      background: url('../images/md/mes-annonces.svg') no-repeat;
      background-size: contain;
    }

  }

  hr {
    margin-top: .5rem;
    margin-bottom: 1rem;
  }

  .ico-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 5px;

  }

  .ico-search {
    background-color: white;
    -webkit-mask: url('../images/loupe.svg') no-repeat 15% 15%;
    mask: url('../images/loupe.svg') no-repeat 15% 15%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  .ico-edit {
    background-color: white;
    -webkit-mask: url('../images/md/general-editer.svg') no-repeat 15% 15%;
    mask: url('../images/md/general-editer.svg') no-repeat 15% 15%;
    -webkit-mask-size: cover;
    mask-size: cover;
  }



}

.card-contact {
  //background-color: #4d9200;
  display: flex;
  width: 49%;
  margin-bottom: 1rem;
  position: relative;

  padding: 5px;
  border : 1px solid #eeeeee;
  border-radius : 5px;


  img {
    width: 40px;
    height: 40px;
    /*background-color: #00B0E8;*/
    border-radius: 20px;
  }

  .card-body {
    width: calc( 100% - 40px);
    text-align: left;
    padding: 0 10px;

    .card-title {
      height: unset;
      line-height: unset;
      margin-bottom: unset;
      font-size: .8rem;
      font-weight: bold;
    }

    .card-text {
       margin-bottom: .5rem;
    }
  }
}

.btn-md-bleu {
  background: linear-gradient(90deg, #42a3dc 0%, #3d94c8 100%);
  border: 0;
  border-radius: 7px;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.2rem;
  padding: 1px 9px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: fit-content;
}

.btn-pic-cross:before{
  content:'';
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background: white url("../images/md/general-fermeture.svg") no-repeat 50%;
  background-size: 50%;
  opacity: .2;
}

.md-dashboard-publication {
  background-color: $md-gris-fonce;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  h2 {
    font-size: 1.2rem !important;
    margin-top: 1rem;
    display: block;
    text-align: center;
    width: 100%;
  }

  div {
    width: 30%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin : 1rem 0;

    span{
      display: block;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    %icon {
      background-color: white;
      width: 60px;
      height: 60px;
      display: block;
      content: "";
      margin-left: calc( 50% - 30px);
      margin-bottom: 1rem;
      -webkit-mask-size: contain;
      mask-size: contain;
    }

    .pub-event:before {
      @extend %icon;
      -webkit-mask: url('../images/md/mes-evenements.svg') no-repeat 15% 15%;
      mask: url('../images/md/mes-evenements.svg') no-repeat 15% 15%;
    }

    .pub-formation:before {
      @extend %icon;
      -webkit-mask: url('../images/md/mes-formations.svg') no-repeat 15% 15%;
      mask: url('../images/md/mes-formations.svg') no-repeat 15% 15%;
    }

    .pub-annonce:before {
      @extend %icon;
      -webkit-mask: url('../images/md/mes-annonces.svg') no-repeat 15% 15%;
      mask: url('../images/md/mes-annonces.svg') no-repeat 15% 15%;
    }

    a {
      display: block;
      border-radius: 5px;
      line-height: 15px;
      text-align: center;
      padding: 5px 5px;
      margin: 5px;
      color: white;
      font-size: .8rem;
    }

    a.btn-gerer {
      background-color: $bleu-ciel;
    }
    a.btn-creer {
      background-color: $md-vert;
    }

  }

}

.sidebar-md {
  min-width: 260px;
}

.nav-md-mobile>div, .nav-md-mobile>ul {
  display: none !important;
}

.intro-md {
  display: flex;
  justify-content: space-between;

  .completion-container{
    color: white;
    width: 48%;
    padding: 15px;
    background-color: $md-vert;
    border-radius: 15px;
    display: flex;
    align-items: center;

    a {
      color: white !important;
    }

    .completion-picto {
        background-color: white;
        min-width: 40px;
        min-height: 40px;
        display: block;
        content: "";
        -webkit-mask: url('../images/md/mon-profil.svg') no-repeat 15% 15%;
        mask: url('../images/md/mon-profil.svg') no-repeat 15% 15%;
    }

    .completion-txt {
      padding: 0 10px;
      font-size: .7rem;
      width: calc( 100% - 140px );

      .profil {
        font-size: .9rem;
        font-weight: bold;
      }
      .percent {
        font-size: 1.3rem;
        font-weight: bold;
      }
    }

    .completion-cta {
      border-left: 1px solid white;
      font-size: .8rem;
      min-width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      span:after {
        display: block;
        width: 15px;
        height: 15px;
        content: "";
        background-color: white;
        -webkit-mask: url('../images/md/general-fleche.svg') no-repeat 15% 15%;
        mask: url('../images/md/general-fleche.svg') no-repeat 15% 15%;
        margin: 10px auto;
        transform: rotate(-90deg);
      }
    }

  }

  .agenda-container{
    display: flex;
    align-items: center;
    border-radius: 15px;
    padding: 15px 30px;
    width: 48%;
    -webkit-box-shadow: -1px -4px 18px 8px rgba(0,0,0,0.06);
    -moz-box-shadow: -1px -4px 18px 8px rgba(0,0,0,0.06);
    box-shadow: -1px -4px 18px 8px rgba(0,0,0,0.06);
    font-weight: bold;
    font-size: .9rem;

    .agenda-picto {
      background-color: #1e2734;
      min-width: 40px;
      min-height: 40px;
      display: block;
      content: "";
      -webkit-mask: url('../images/md/general-agenda.svg') no-repeat 15% 15%;
      mask: url('../images/md/general-agenda.svg') no-repeat 15% 15%;
    }

    .agenda-txt {
      padding-left: 20px;
    }

    a {
      text-decoration: underline;
    }
  }
}

.notif-person-dummy {
  width: 33%;
  display: flex;
  align-items: flex-start;
}

.notif-person {
  width: 33%;
  display: flex;
  align-items: flex-start;

  padding: 5px;
  border : 1px solid #eeeeee;
  border-radius : 5px;

  p {
    font-size: .8rem;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin: 5px 10px 5px 5px;
  }

  .link-refuse-accept {
    text-decoration: underline;
  }

  .btn-cross::before {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
    right: -5px;
    top: -5px;
    border-radius: 10px;
    background: white url('../images/md/general-fermeture.svg') no-repeat 50%;
    background-size: 50%;
    opacity: 0.5;
  }
}

@media (max-width: 1200px) {
  .sidebar-md {
    display: none;
  }
  #content-md {
    padding-left: min(2%);
  }
}

@media (max-width: 992px) {

  .bubble-profil {
    margin-left: unset !important;
  }

  .notif-person {
    width: 49%;
    display: flex;
    align-items: flex-start;

    p {
      font-size: .9rem;
    }

  }

  .card-contact {
    width: 100%;
  }

  .nav-md-mobile>div, .nav-md-mobile>ul {
    display: unset !important;
  }

  .nav-md-mobile {
    display: block;
    //position: fixed;
    /*left: 0;
    right: 0;*/
    /*top: 70px;*/
    background: $md-gris-fonce;
    //height: calc( 100vh - 60px);
    height: fit-content;

    .block-dr, .mobile-md-logout {
      background-color: rgba(255,255,255,.1);
      margin: 1rem;
      padding: .3rem;
      border-radius: 7px;
      color: white;
      font-weight: bold;
      width: auto;
      display: flex !important;
      justify-content: flex-start;
      align-items: center;
      height: 45px;
    }

    .mobile-md-logout a {
      color: white;
      display: flex;
    }

    .mobile-md-logout a::before {
      margin-left: 8px;
    }

    li{
      border-bottom: 1px solid rgba(255,255,255,.1);
    }

    .last-li { border-bottom: none !important;}

    .item {
      color: white;
      margin: .5rem 1rem;
      padding: .3rem;
      display: flex;
    }

    .item:hover {
      color: $md-vert !important;
    }


  }

  .md-dashboard-publication {
    div {
      width: 100%;
    }
  }


  #nav-md {
    display: none;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: #2f3944;
    z-index: 2;

    .logo {
      display: none;
    }

    .item {
      width: 100%;
      color: white;
      border-radius: unset;
      border-bottom: 1px solid #5c6671;
      transition: border-left unset;
      line-height: 50px;
    }

    .item:hover {
      background-color: unset;
      border-left: unset;
      transition: border-left unset;
    }

    .item.active {
      border-left: unset;
      color: $md-vert;
    }
  }

  #content-md {
    padding-left: unset;

    .logo-md-mobile {
      display: block;
      width: 90px;
      height: 25px;
      background-image: url("../images/logo-my-dentaire.png");
      background-size: contain;
      background-repeat: no-repeat;
    }

  }
}

@media (max-width: 768px) {

  .intro-md {
    flex-wrap: wrap;
  }
  .completion-container{
    width: 100% !important;
    margin-bottom: 20px;
  }
  .agenda-container{
    width: 100% !important;
  }

  .notif-person {
    width: 100%;
    display: flex;
    align-items: flex-start;

    p {
      font-size: .9rem;
    }
  }

}
