// Montserrat
// 400 : Regular
// 500 : Medium
// 600 : Semi bold
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

$font-family-sans-serif: 'Montserrat', Helvetica, Arial, sans-serif !default;

@font-face {
  font-family: 'Optie-bold';
  src: url('../fonts/optie-bold/OPTIEdgarBold-Extended.eot');
  src: url('../fonts/optie-bold/OPTIEdgarBold-Extended.eot?#iefix') format('embedded-opentype'),
  url('../fonts/optie-bold/OPTIEdgarBold-Extended.woff') format('woff'),
  url('../fonts/optie-bold/OPTIEdgarBold-Extended.ttf') format('truetype'),
  url('../fonts/optie-bold/OPTIEdgarBold-Extended.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Optie-extended';
  src: url('../fonts/optie-extended/OPTIEdgar-Extended.eot');
  src: url('../fonts/optie-extended/OPTIEdgar-Extended.eot?#iefix') format('embedded-opentype'),
  url('../fonts/optie-extended/OPTIEdgar-Extended.woff') format('woff'),
  url('../fonts/optie-extended/OPTIEdgar-Extended.ttf') format('truetype'),
  url('../fonts/optie-extended/OPTIEdgar-Extended.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}