.fiche-event {
  .block-date{
    width: 100%;
    background-color: $bleu-fonce;
    border-radius: 7px;
    color: $white;
    font-size: .8rem;
    height: 2rem;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .block-exposant {
    color: $white;
    border-radius: 7px;
    background-color: $bleu-fonce;
    width: 100%;
    padding: 4px 15px;
    font-size: .9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $white;
      text-decoration: underline;
    }

    .logo {
      border-radius: 0;
      max-width: 150px;
      max-height: 50px;
      margin-right: 15px;
    }

  }

  p {
    font-size: .8rem;
    margin-bottom: .2rem;
  }

  ul{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

  }

  li {
    line-height: 2rem;
    border-radius: 2rem !important;
    border: none;
    padding: 0 15px 0 15px;
    background-color: $gris-clair;
    margin: 15px 30px 0 0;
    font-size: .8rem;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  }

  .ttes-items {
    font-size: .8rem;
    text-decoration: underline;
  }

}
