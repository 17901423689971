header {
  padding-top: 1.7rem;

  a.nav-link {
    color: $white !important;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    text-decoration: none;
    text-align: center;
    line-height: 1.2rem;
  }
  a:hover, a:hover > span {
    color: $bleu-ciel !important;
  }
  a.nav-link .annuaire {
    color: $orange;
  }
  a.nav-link .dentaire {
    color: $rouge;
  }
  .href-logo {
    margin: 0;
    padding: 0;
    text-decoration: none;
    width: 100%;
  }

  .container {
    padding: 0;
    margin: 0;
  }

  .active > .nav-link,   .active > .nav-link > span {
      color: $bleu-ciel !important;
  }

  .nav-item {
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .nav-link {
    padding-bottom: 0;
  }

  .nav-desktop {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 min(5%);
    flex-flow: row wrap;
  }

  .logo {
    color: $white;
    font-family: "Optie-extended", Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    padding-left: 5%;

    .dentaire {
      font-family: "Optie-bold", Helvetica, Arial, sans-serif;
    }
    .point {
      color: $bleu-ciel;
      font-family: Times, serif;
      font-size: 40px;
      margin-left: 3px;
      margin-right: 4px;
    }

    .com {
      font-size: 1.3rem;
    }
  }

  .has-megamenu {
    position: static;
  }

  .has-megamenu:hover .dropdown-menu {
    display: block;
  }

  .megamenu {
    @extend %width-full-content;

    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 16px 0 rgba(0, 0, 0, 0.2);
    left: 0;
    padding-top: 2rem;
    padding-bottom: 2.5rem;
    right: 0;
    width: 100%;
    margin: 0;

    a {
      color: $bleu-gris;
      padding: 0;
      margin: 0;
      text-decoration: none;
    }

    span a {
      font-size: 0.9rem;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    span {
      display: block;
      height: 2rem;
      padding: 0;
    }

    li a {
      font-size: 0.8rem;
      font-weight: 400;
      padding-bottom: 0.5rem;
    }

    .li-savoir-plus a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  li.nav-my-dentaire a{
    color: $md-vert !important;
  }
}

.navbar-toggler {
  border: 0;

  span {
    transition: background-image 0.35s ease-in-out;
  }
}

.navbar-toggler-account {
  display: none;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("../images/menu.svg");
  height: 25px;
  width: 25px;
  margin-top: 12px;
  background-size: 15px;
  opacity: 0.7;
}


.navbar-toggler-icon-account {
  background: url("../images/user.svg") 50%/100% 70% no-repeat;
  height: 25px;
  width: 25px;
  margin-top: 12px;
  opacity: 0.7;
  display: inline-block;
  vertical-align: middle;
  content: "";

}

.navbar-dark .navbar-toggler-icon.cross, .navbar-dark .navbar-toggler-icon-account.cross {
  background-image: url("../images/cross.svg");
  background-repeat: no-repeat;
  display: block;
  height: 25px;
  margin-top: 12px;
  opacity: 0.7;
  width: 25px;
  background-size: 15px;
}

.connexion .not-connected {
  position: relative;
  padding-left: 25px !important;
}

.connexion .not-connected::before {
  background-image: url("../images/user.svg");
  height: 17px;
  opacity: 0.7;
  width: 15px;
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 10px;
}

.back,
.parent-page {
  min-height: 52px;
  font-weight: 600;
  font-size: 0.9rem;
  background: #eeeef3;
  position: relative;
  padding: 0.5rem 1rem !important;
  transition: min-height 0.4s ease-in-out, height 0.4s ease-in-out,
    padding 0.4s ease-in-out;
}

.back {
  padding: 0.5rem 2rem !important;
}

.back::before {
  background-image: url("../images/arrow-accordion.svg");
  height: 15px;
  width: 15px;
  content: "";
  background-repeat: no-repeat;
  position: absolute;
  left: 9px;
  top: 21px;
  background-size: 10px;
  transform: rotate(90deg);
}

.parent-page {
  background: transparent;
  padding: 0 !important;

  a {
    display: block;
    color: $bleu-ciel !important;
    font-size: 1.2rem !important;
    padding: 0.5rem 1rem !important;
  }
  a::before {
    display: none;
  }
}

.item-compte {
  display: flex;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: rgba(255,255,255,.2);

  li {
    display: flex;
    align-items: center;
    padding-top: .3rem !important;
  }

  a {
    padding-top: 0 !important;
  }
}

.user-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(255,255,255,.3);
  margin-right: .5rem;
}

.done > .back,
.done > .parent-page,
.submenu-list:not(.shown) > .back,
.submenu-list:not(.shown) > .parent-page,
.sub-menu:not(.shown) .submenu .dropdown-header {
  min-height: 0;
  height: 0 !important;
  padding: 0 !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {align-items: center;}
}

@media (max-width: 992px) {
  header {
    position: sticky;
    z-index: 10000;
    transition: height 0.35s ease;
    margin-top: 0;
    padding-top: 0;
    width: 100%;
    overflow: hidden;
    height: 70px;
    top: 0;

    .navbar {
      padding: 0;

      .href-logo {
        width: auto;
        padding-left: 20px;

        .logo {
          font-size: 0.9rem;
          display: block;

          .com {
            font-size: 0.6rem;
          }
        }
      }

      .connexion {
        width: 25px;
        overflow: hidden;
        display: none;

        .not-connected::before {
          left: 10px;
          top: 15px;
        }
      }

      .navbar-toggler-account {
          display: block;
          padding: 0.25rem 0.75rem;
          font-size: 1.25rem;
          line-height: 1;
          background-color: transparent;
          border: 1px solid transparent;
          border-radius: 0.25rem;
      }

      .navbar-nav.done {
        left: -100%;
      }

      .nav-item {
        padding: 0;
      }

      .navbar-collapse {
        padding-top: 10px;
      }

      li.has-megamenu div.sub-menu,
      .submenu-list {
        width: 100%;
        position: absolute;
        top: 0;
        left: 200% !important;
        transition: left 0.4s ease-in-out;
        height: auto;
        background-color: #ffffff;
        padding: 0;
        box-shadow: none;
      }

      div.sub-menu {
        .container {
          max-width: 100%;
          display: block;

          .row {
            margin: 0;

            .dropdown-header {
              height: 100%;

              a {
                display: block;
                position: relative;
                padding: 0.5rem 1rem;
                font-size: 1rem;
                font-weight: 600;
              }
            }

            .submenu {
              padding: 0;

              .submenu-list.shown {
                left: 200% !important;
              }

              .submenu-list {
                left: 300% !important;
              }

              .submenu-list li a {
                display: block;
                position: relative;
                padding: 0.5rem 1rem;
                font-size: 1rem;
                font-weight: 600;
              }

              .submenu-list li a::before {
                display: none;
              }

              .submenu-list li a::after {
                width: 100%;
                content: "";
                border: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0;
                padding: 0;
              }

              .submenu-list li:nth-child(2) a::after {
                display: none;
              }
            }
          }
        }
      }

      li.has-megamenu div.sub-menu.done {
        left: -100% !important;
      }

      li.has-megamenu div.sub-menu.shown {
        left: 100% !important;

        .dropdown-header a::after {
          width: 100%;
          content: "";
          border: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0;
          padding: 0;
        }
      }
    }

    .navbar-collapse > ul > li a.nav-link::after {
      width: 100%;
      content: "";
      border: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      padding: 0;
    }

    .navbar-collapse > ul > li:first-child a.nav-link::after {
      border: none;
    }

    .navbar-collapse > ul > li.has-megamenu a::before {
      width: 15px;
      height: 15px;
      content: "";
      background-image: url("../images/arrow-accordion-open.svg");
      background-repeat: no-repeat;
      position: absolute;
      bottom: 0;
      right: 5%;
      top: 0;
      background-size: 10px;
      margin: auto;
      transform: rotate(270deg);
    }

    .navbar-collapse > ul {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
      transition: left 0.4s ease-in-out;
      left: 0;
    }

    .navbar-collapse > ul > li,
    .dropdown-header,
    .submenu-list li,
    .parent-page {
      width: 100%;
      line-height: 36px;
      display: block;
    }

    .navbar-collapse > ul > li a.nav-link,
    .dropdown-header a,
    .submenu-list li > a,
    .parent-page a {
      padding-left: 5% !important;
      padding-bottom: 0.5rem !important;
    }
  }

  header.fullHeight {
    height: 100vh;
  }
}

@media (max-width: 425px) {
  header .navbar .href-logo {
    padding-left: 12px;
  }
}

@media (max-width: 375px) {
  header .navbar .navbar-toggler {
    margin-right: 10px;
  }

  header .navbar .href-logo .logo {
    font-size: 0.6rem;
  }

  header .navbar .href-logo .logo .com {
    font-size: 0.5rem;
  }
}
