.breadcrumb {
  max-width: $max-content-width;
  margin: 0 auto;
  padding-left: min(5%);
  padding-right: min(5%);
  background-color: $white;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  font-size: .7rem;

  li {
    padding-left: 5px;
  }

  a:hover{
    text-decoration: underline;
  }

  li:last-child > span{
    font-weight: bold;
  }

  .separator {
    //font-size: .5rem;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
  }

}

@media (max-width: 1024px) {


}