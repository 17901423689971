.fiche-produit {

  .block-exposant {
    color: $white;
    border-radius: 7px;
    background-color: $bleu-fonce;
    width: 100%;
    padding: 4px 15px;
    font-size: .9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: $white;
      text-decoration: underline;
    }

    .logo {
      border-radius: 0;
      max-width: 150px;
      max-height: 50px;
      margin-right: 15px;
    }

  }

  p {
    font-size: .8rem;
  }

  img {
    border-radius: 7px;
  }

  @media (max-width: 1024px) {

    small::before {
      content: "\a";
      white-space: pre;
    }

  }



}
