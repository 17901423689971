.pagination {

  margin-bottom: 2rem;

  div {
    display: flex;
    flex-wrap: wrap;
  }

  ul {
    display: inline-flex;
    margin: 0 auto;
  }

  li {
    padding : 0 .55rem;
    font-size: .8rem;
  }

  a:hover{
    font-weight: bold;
    text-decoration: underline;
  }

  .active {
    font-weight: bold;
  }

  //.hellhip{
  //  padding: .6rem;
  //  display: block;
  //}

}

@media (max-width: 1024px) {

  .pagination {
    flex-wrap: wrap;
  }

}